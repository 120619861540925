import _ from 'lodash';
import moment from 'moment';
import * as INPUT_STATE from '@utilities/constants/inputState';
import * as AXCESS_KEYS from '@utilities/constants/axcessKeys';
import * as TRIGGER_KEYS from '@utilities/constants/triggerKeys';
import * as LOOKUPS from '@utilities/constants/lookupInfo';
import * as FIELD_TYPE from '@utilities/constants/fieldType';
import { parseToUSDate } from '@components/formRenderer/fields/utils/formatDate';
import { MM_DD_YYYY, YYYY_MM_DD } from '@utilities/constants/dateFormats';
// import moneyFormatter, {
//   noDollarFormatter,
//   parseMoneyToNumber,
// } from './moneyFormatter';
import moneyFormatter, {noDollarFormatter, parseMoneyToNumber} from '../utilities/moneyFormatter';

const zipPostalPull = (values) => {
  const [foreignCountry, zipCode, postalCode] = values;

  if (foreignCountry) return postalCode;
  if (!foreignCountry) return zipCode;
  return null;
};

const foreignCountryPull = (axcessValues) => {
  if (!axcessValues) return null;

  const [foreignCountry] = axcessValues;

  if (!foreignCountry) return TRIGGER_KEYS.KEY_UNITED_STATES;
  if (foreignCountry === TRIGGER_KEYS.KEY_EMPTY)
    return TRIGGER_KEYS.KEY_UNITED_STATES;
  if (foreignCountry.toLowerCase() === TRIGGER_KEYS.KEY_NA)
    return TRIGGER_KEYS.KEY_UNITED_STATES;

  return foreignCountry;
};

const americanStatesPull = (axcessValues) => {
  if (!axcessValues) return null;

  const [foreignCountry, state] = axcessValues;

  const country = foreignCountryPull([foreignCountry]);

  if (!country) return null;
  if (country === TRIGGER_KEYS.KEY_UNITED_STATES) return state;
  return null;
};

const canadianProvincePull = (axcessValues) => {
  if (!axcessValues) return null;

  const [foreignCountry, province] = axcessValues;

  const country = foreignCountryPull([foreignCountry]);

  if (!country) return null;
  if (country === TRIGGER_KEYS.KEY_CANADA) return province;
  return null;
};

const mexicanStatesPull = (axcessValues) => {
  if (!axcessValues) return null;

  const [foreignCountry, state] = axcessValues;

  const country = foreignCountryPull([foreignCountry]);

  if (!country) return null;
  if (country === TRIGGER_KEYS.KEY_MEXICO) return state;
  return null;
};

const foreignProvincePull = (axcessValues) => {
  if (!axcessValues) return null;

  const [foreignCountry, province] = axcessValues;

  const country = foreignCountryPull([foreignCountry]);

  if (!country) return null;
  if (
    country !== TRIGGER_KEYS.KEY_CANADA &&
    country !== TRIGGER_KEYS.KEY_UNITED_STATES
  )
    return province;
  return null;
};

const childCareExpensesPull = (values) => {
  const filterValid = (x) => x && _.toSafeInteger(x) !== 0;
  if (!values || !_.isArray(values)) return null;

  const hasCareExpense = values.filter(filterValid).length ? true : false;

  return hasCareExpense ? AXCESS_KEYS.AXCESS_KEY_YES : null;
};

const datePull = (value) => parseToUSDate(value);

const employerIdPull = (value) => {
  if (!value) return null;
  return value.replace('-', '');
};

const jointToTaxpayerPull = (value) => {
  if (value === AXCESS_KEYS.JOINT_KEY) return AXCESS_KEYS.TAXPAYER_KEY;
  return value;
};

const checkboxPull = (value) => {
  if (!value || !_.isArray(value)) return null;

  const fieldValue = value.find((x) => x);
  return fieldValue === AXCESS_KEYS.AXCESS_KEY_X ? true : false;
};

const checkboxYNPull = (value) =>
  value === AXCESS_KEYS.AXCESS_KEY_YES ? true : false;

const checkboxToYNPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues) || !axcessValues.length) return null;

  const result = axcessValues.find((x) => x);

 return result === AXCESS_KEYS.AXCESS_KEY_X ? 'Yes' : null;
};

const selectYNPull = (value) => {
  switch (value) {
    case AXCESS_KEYS.AXCESS_KEY_Y:
      return AXCESS_KEYS.AXCESS_KEY_YES;
    case AXCESS_KEYS.AXCESS_KEY_N:
      return AXCESS_KEYS.AXCESS_KEY_NO;
    default:
      return '';
  }
};

const withdrawalPull = (value) =>
  value === AXCESS_KEYS.AXCESS_KEY_BOTH ? AXCESS_KEYS.EXCHANGE_KEY_BOTH : value;
const maritalStatusPull = (value) => {
  const SINGLE = [TRIGGER_KEYS.EVENT_KEY_1, TRIGGER_KEYS.EVENT_KEY_3];
  const MARRIED = [
    TRIGGER_KEYS.EVENT_KEY_2,
    TRIGGER_KEYS.EVENT_KEY_5,
    TRIGGER_KEYS.EVENT_KEY_6,
    TRIGGER_KEYS.EVENT_KEY_7,
  ];
  const WIDOWED = [TRIGGER_KEYS.EVENT_KEY_4];

  if (SINGLE.includes(value)) {
    return TRIGGER_KEYS.KEY_SINGLE;
  } else if (MARRIED.includes(value)) {
    return TRIGGER_KEYS.KEY_MARRIED;
  } else if (WIDOWED.includes(value)) {
    return TRIGGER_KEYS.KEY_WIDOWED;
  }

  return value;
};

const foreignAccountTypePull = (values) => {
  if (!values) return null;
  const [foreignAccount] = values;

  if (foreignAccount) return foreignAccount;
  return AXCESS_KEYS.FOREIGN_ACC_TYPE_OTHER;
};

const foreignAssetsIdTypePull = (values) => {
  if (!values) return null;
  const [idType] = values;

  switch (idType) {
    case AXCESS_KEYS.FOREIGN_ASSETS_ID_TYPE_FOREIGN:
      return TRIGGER_KEYS.KEY_FOREIGN;
    case AXCESS_KEYS.FOREIGN_ASSETS_ID_TYPE_EIN:
      return TRIGGER_KEYS.KEY_EIN;
    case AXCESS_KEYS.FOREIGN_ASSETS_ID_TYPE_SSN:
      return TRIGGER_KEYS.KEY_SSN;
    default:
      return null;
  }
};

const translateFunctions = {
  date: datePull,
  checkbox: checkboxPull,
  YNcheckbox: checkboxYNPull,
  selectYN: selectYNPull,
  electronicWithdrawal: withdrawalPull,
  maritalStatus: maritalStatusPull,
  employerId: employerIdPull,
  jointToTaxpayer: jointToTaxpayerPull,
  // Define other functions for translation when pulling prior year data
};

const translatePullData = (axcessTranslate, value) => {
  if (!axcessTranslate || !translateFunctions[axcessTranslate]) return value;

  return translateFunctions[axcessTranslate](value);
};

const stateLocalIncomeRefundPull = (values) => {
  if (!_.isArray(values)) {
    return null;
  }

  const tsj = stateLocalTsjPull(values);
  const taxpayerRefund = _.toSafeInteger(values[9]);
  const spouseRefund = _.toSafeInteger(values[23]);

  if (tsj === AXCESS_KEYS.JOINT_KEY) return `${taxpayerRefund + spouseRefund}`;
  return `${taxpayerRefund}`;
};

const stateLocalTsjPull = (values) => {
  const filterValid = (x) => x && _.toSafeInteger(x) !== 0;

  if (!_.isArray(values)) {
    return AXCESS_KEYS.TAXPAYER_KEY;
  }

  const maritalStatus = values[0];
  const hasJointValues = values.slice(1, 15).filter(filterValid).length
    ? true
    : false;
  const hasSpouseValues = values.slice(15).filter(filterValid).length
    ? true
    : false;

  if (
    maritalStatus === TRIGGER_KEYS.EVENT_KEY_2 &&
    hasJointValues &&
    !hasSpouseValues
  )
    return AXCESS_KEYS.JOINT_KEY;
  if (
    maritalStatus === TRIGGER_KEYS.EVENT_KEY_2 &&
    hasJointValues &&
    hasSpouseValues
  )
    return AXCESS_KEYS.JOINT_KEY;
  return AXCESS_KEYS.TAXPAYER_KEY;

  // Taxpayer or Joint is 1 - 14
  // Spouse 15 - 28
  // If filing status on return is married filing joint and just a value under taxpayer or joint, mark it joint and using the value from just taxpayer column
  // If filing status on return is married filing joint and value under taxpayer or joint AND spouse, mark it joint and use the value from taxpayer and spouse combined
  // If filing status on return is anything else, mark it taxpayer and use just taxpayer value
};

const stateLocalIncomeCityPull = (values) => {
  if (!values && !_.isArray(values)) return null;
  if (values[1] === 'city' && values[0]) return values[0];
  return null;
};

const stateLocalIncomeStatePull = (values) => {
  if (!values && !_.isArray(values)) return null;
  if (values[1] === 'state' && values[0]) return values[0];
  return null;
};

const unemploymentCompTSJPull = (values) => {
  const [miscZero, cerGovZero] = values;

  if (miscZero) return miscZero;
  if (cerGovZero) return cerGovZero;

  return 'T';
};

const unemploymentCompDescPull = (values) => {
  const [miscFive, cerGovFive] = values;

  if (miscFive) return miscFive;
  if (cerGovFive) return cerGovFive;

  return null;
};

const iraTypePull = (axcessValues) => {
  if (!axcessValues) return null;

  const amounts = axcessValues.map((x) => _.toSafeInteger(x));

  const [pyContributions, cyContributions, rothContributions] = amounts;

  if (pyContributions || cyContributions)
    return AXCESS_KEYS.AXCESS_KEY_TRADITIONAL;
  if (rothContributions) return AXCESS_KEYS.AXCESS_KEY_ROTH;

  return null;
};

const iraAmountPull = (axcessValues) => {
  const iraType = iraTypePull(axcessValues);

  if (!iraType) return null;

  const amounts = axcessValues.map((x) => _.toSafeInteger(x));
  const [pyContributions, cyContributions, rothContributions] = amounts;

  switch (iraType) {
    case AXCESS_KEYS.AXCESS_KEY_TRADITIONAL:
      return `${pyContributions + cyContributions}`;
    case AXCESS_KEYS.AXCESS_KEY_ROTH:
      return `${rothContributions}`;
    default:
      return null;
  }
};

const sepTypePull = (axcessValues) => {
  if (!axcessValues) return null;

  const [benCB, sepCB, simCB, ...amounts] = axcessValues;
  const numAmounts = amounts.map((x) => _.toSafeInteger(x));
  const [ben1, ben2, ben3, sep1, sep2, sim1, sim2] = numAmounts;

  if (benCB) return AXCESS_KEYS.AXCESS_KEY_BENEFIT;
  if (sepCB) return AXCESS_KEYS.AXCESS_KEY_SEP;
  if (simCB) return AXCESS_KEYS.AXCESS_KEY_SIMPLE;

  if (ben1 || ben2 || ben3) return AXCESS_KEYS.AXCESS_KEY_BENEFIT;
  if (sep1 || sep2) return AXCESS_KEYS.AXCESS_KEY_SEP;
  if (sim1 || sim2) return AXCESS_KEYS.AXCESS_KEY_SIMPLE;

  return null;
};

const sepAmountPull = (axcessValues) => {
  const sepType = sepTypePull(axcessValues);

  if (!sepType)
    return `${_.toSafeInteger(axcessValues[axcessValues.length - 1])}` || null;

  /* eslint-disable no-unused-vars */
  const [benCB, sepCB, simCB, ...amounts] = axcessValues;
  const numAmounts = amounts.map((x) => _.toSafeInteger(x));
  const [ben1, ben2, ben3, sep1, sep2, sim1, sim2] = numAmounts;

  switch (sepType) {
    case AXCESS_KEYS.AXCESS_KEY_BENEFIT:
      if (!ben1 && !ben2 && !ben3)
        return `${numAmounts[numAmounts.length - 1]}` || null;
      return `${ben1 + ben2 + ben3}`;
    case AXCESS_KEYS.AXCESS_KEY_SEP:
      if (!sep1 && !sep2) return `${numAmounts[numAmounts.length - 1]}` || null;
      return `${sep1 + sep2}`;
    case AXCESS_KEYS.AXCESS_KEY_SIMPLE:
      if (!sim1 && !sim2) return `${numAmounts[numAmounts.length - 1]}` || null;
      return `${sim1 + sim2}`;
    default:
      return `${numAmounts[numAmounts.length - 1]}` || null;
  }
};

const farmCropInsurancePull = (axcessValues) => {
  if (!axcessValues) return '0';

  const amounts = axcessValues.map((x) => _.toSafeInteger(x));
  const [val1, val2] = amounts;

  const cropInsuranceAmt = (val1 || 0) - (val2 || 0);
  return cropInsuranceAmt < 0 ? '0' : `${cropInsuranceAmt}`;
};

const farmGasFuelPull = (axcessValues) => {
  if (!axcessValues) return '0';

  const amounts = axcessValues.map((x) => _.toSafeInteger(x));
  const [fuelAmt] = [amounts.reduce((a, b) => a + b, 0)];

  return `${fuelAmt || '0'}`;
};

const anyValidPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  return axcessValues.find((x) => x) || null;
};

const anyValidPercentPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues) || !axcessValues.length)
    return null;

  return (axcessValues.find((x) => x) * 100)?.toFixed(2) || null;
};

const accountTypePull = (axcessValues) => {
  if (!axcessValues) return LOOKUPS.ACCOUNT_TYPE[1].value;

  if (
    _.toUpper(axcessValues).includes(
      _.toUpper(AXCESS_KEYS.EXCHANGE_KEY_CHECKING)
    )
  ) {
    return LOOKUPS.ACCOUNT_TYPE[1].value;
  }

  if (
    _.toUpper(axcessValues).includes(
      _.toUpper(AXCESS_KEYS.EXCHANGE_KEY_SAVINGS)
    )
  ) {
    return LOOKUPS.ACCOUNT_TYPE[2].value;
  }

  return LOOKUPS.ACCOUNT_TYPE[1].value;
};

const sumAllPull = (axcessValues) => {
  if (!axcessValues || !_.isArray) return null;

  const amounts = axcessValues.map((x) => _.toSafeInteger(x));
  const amount = amounts.reduce((prev, curr) => prev + curr, 0);

  return `${amount}`;
};

const vehicleOtherMilesPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  const miles = axcessValues.map((x) => _.toSafeInteger(x));
  const otherMilesAmount = miles[0] - miles[1] - miles[2];
  return otherMilesAmount > 0 ? `${otherMilesAmount}` : null;
};

const foreignAssetsBankInfoLabelPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  let bankName = axcessValues[0] || '';
  const maskedAccountNumber =
    ('' + axcessValues[1]).slice(0, -4).replace(/./g, 'X') +
    ('' + axcessValues[1]).slice(-4);
  let accountNumber = axcessValues[1] ? `Acct #: ${maskedAccountNumber}` : '';

  if (bankName.length > 20) {
    bankName = `${bankName.substring(0, 17)}...`;
  }

  if (accountNumber.length > 20) {
    accountNumber = `${accountNumber.substring(0, 17)}...`;
  }

  if (!bankName && !accountNumber) return null;
  return `${bankName}\n${accountNumber}`;
};

const foreignAssetsAccountHeldJointlyOther = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  const ownershipType = axcessValues[0];
  if (ownershipType === AXCESS_KEYS.FOREIGN_ASSETS_JOINT_OWNERSHIP_OTHER) {
    return true;
  }

  return null;
};

const foreignIncomeHomeAddressPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  const addresses = axcessValues.filter((x) => x?.trim());

  return addresses.length > 0 ? addresses.join(', ') : '';
};

const attachOrTablePull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  const addresses = axcessValues.filter((x) => x?.trim());

  return addresses.length > 0 ? 'Table' : null;
};

const foreignIncomeHomeOccupantPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  const addresses = axcessValues
    .map((row) => {
      if (!row || !_.isArray(row)) return null;

      return row
        .map((x) => x?.trim())
        .filter((x) => x)
        .join(' ');
    })
    .filter((x) => x && x.length);

  return addresses.length > 0 ? addresses : [];
};

const dependentsSummaryPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  const [relation, ...names] = axcessValues;

  // Base relationship status on lookup
  const relationship =
    LOOKUPS?.RELATIONSHIP?.find(
      (x) => x.value === relation && relation
    )?.name?.trim() ?? '';
  let name = names
    ?.filter((x) => x && _.isString(x) && x?.trim())
    ?.map((x) => x?.trim())
    ?.join(' ');

  return `${name}\n${relationship}`;
};

const odtkeCompensationPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  const [isIndividual, isInstitutional, isKeyEmployee, isHighestCompensated] =
    axcessValues;

  if (isIndividual) return 'Director';
  if (isInstitutional) return 'Institutional Trustee';
  if (isKeyEmployee) return 'Key Employee';
  if (isHighestCompensated) return 'Highest Comp EE';
  return null;
};

const odtkeLabelPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  let indvName = axcessValues[0] || '';
  let indvTitle = axcessValues[1] || '';

  if (indvName.length > 20) {
    indvName = `${indvName.substring(0, 17)}...`;
  }

  if (indvTitle.length > 20) {
    indvTitle = `${indvTitle.substring(0, 17)}...`;
  }

  let format = `Name: ${indvName || ''}\nTitle: ${indvTitle || ''}`;
  return format;
};

const taxExemptStatusPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  const [section501, section4947, section527] = axcessValues;

  if (
    section501 &&
    _.toSafeInteger(section501) &&
    _.toSafeInteger(section501) > 0 &&
    _.toSafeInteger(section501) <= 29
  ) {
    // check if section501 is a number between 1 and 29
    // compile into 501(c)(#)
    return `501(c)(${section501})`;
  }
  if (section4947) return '4947(a)(1)';
  if (section527) return '527';

  return null;
};

const publicCharityStatusPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  const [
    church,
    school,
    hospital,
    medResearch,
    university,
    govUnit,
    publicFunded,
    communityTrust,
    agResearch,
    grossReceipts,
    publicSafety,
    typeI,
    typeII,
    typeIIIFunctionally,
    typeIIINonFunctionally,
  ] = axcessValues;

  if (church) return '170(b)(1)(A)(i)–Church'; // .0
  if (school) return '170(b)(1)(A)(ii)-School'; // .1
  if (hospital) return '170(b)(1)(A)(iii)-Hospital'; // .2
  if (medResearch) return '170(b)(1)(A)(iii)-Med Research Org'; // .4
  if (university) return '170(b)(1)(A)(iv)-College/University'; // .6
  if (govUnit) return '170(b)(1)(A)(v)–Gov Unit'; // .3
  if (publicFunded) return '170(b)(1)(A)(vi)-Gov/Public Funded'; // .7
  if (communityTrust) return '170(b)(1)(A)(vi)-Community Trust'; // .8
  if (agResearch) return '170(b)(1)(A)(ix)–Ag Research Org'; // .15
  if (grossReceipts) return '509(a)(2)–Gross Receipts Test'; // .9
  if (publicSafety) return '509(a)(4)–Public Safety'; // .13
  if (typeI) return '509(a)(3)–Type I'; // .10
  if (typeII) return '509(a)(3)–Type II'; // .11
  if (typeIIIFunctionally) return '509(a)(3)–Type III Functionally Int.'; // .12
  if (typeIIINonFunctionally) return '509(a)(3)–Type III Non-functionally Int.'; // .14

  return null;
};

const removeHyphensPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;
  if (
    !axcessValues[0] ||
    !_.isString(axcessValues[0]) ||
    !axcessValues[0].trim()
  )
    return null;

  return axcessValues[0].trim().replaceAll('-', '');
};

const joinTextPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  return axcessValues
    .filter((x) => x && _.isString(x))
    .map((x) => x.trim())
    .join(' ');
};

const joinTextOnNewLinePull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  return axcessValues
    .filter((x) => x && _.isString(x))
    .map((x) => x.trim())
    .join('\n');
};

const missionStatementPull = (code) => (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues) || axcessValues.length !== 3)
    return null;

  if (axcessValues[0]) return `${axcessValues[0]}`;

  return fieldMatchTextPull(code)([axcessValues[1], axcessValues[2]]);
};

const fieldMatchTextPull = (matchValue) => (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  // Index 0 is the field value to be pulled
  // Index 1 is the field value to be matched
  return (axcessValues[1]?.toLowerCase() === matchValue?.toLowerCase() || axcessValues[1]?.toLowerCase()?.includes(matchValue?.toLowerCase())) && axcessValues[0]
    ? `${axcessValues[0]}`
    : null;
};

const pullIfFieldMatches = (axcessValues, data) => {
  if (!data || !data.matchValue) return null;

  return fieldMatchTextPull(data.matchValue)(axcessValues);
};

const programsMSPull = (axcessValues) =>
  missionStatementPull(AXCESS_KEYS.MS_CODE_2)(axcessValues);
const basicDataMSPull = (axcessValues) =>
  missionStatementPull(AXCESS_KEYS.MS_CODE_1)(axcessValues);

const govMbrsStockPull = (axcessValues) =>
  fieldMatchTextPull(AXCESS_KEYS.GOV_CODE_5)(axcessValues);
const govBodyGovPull = (axcessValues) =>
  fieldMatchTextPull(AXCESS_KEYS.GOV_CODE_6)(axcessValues);
const govBodyNonGovPull = (axcessValues) =>
  fieldMatchTextPull(AXCESS_KEYS.GOV_CODE_7)(axcessValues);
const govBodyContExpPull = (axcessValues) =>
  fieldMatchTextPull(AXCESS_KEYS.GOV_CODE_8)(axcessValues);
const govBodyContCmtePull = (axcessValues) =>
  fieldMatchTextPull(AXCESS_KEYS.GOV_CODE_9)(axcessValues);
const govBodyWrtnPolLocalChptsExpPull = (axcessValues) =>
  fieldMatchTextPull(AXCESS_KEYS.GOV_CODE_10)(axcessValues);
const fsRptAcctFedReqAudMissExpPull = (axcessValues) =>
  fieldMatchTextPull(AXCESS_KEYS.EXPLANATION_CODE_24)(axcessValues);

const fsRptAcctMthdPYPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return AXCESS_KEYS.CASH;

  if (axcessValues[0] === AXCESS_KEYS.AXCESS_KEY_X) {
    return AXCESS_KEYS.ACCRUAL;
  } else if (axcessValues[1]) {
    return AXCESS_KEYS.OTHER;
  }
};

const gridToMultiselectPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues) || !axcessValues[0])
    return null;

  return axcessValues[0];
};

const checkboxValuePull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  return axcessValues[0] ? true : null;
};

const fiscalYearPull = (axcessValues) => {
  if (!_.isArray(axcessValues)) return null;

  if (!axcessValues || axcessValues[0] === null) return 'Yes';

  const match =
    axcessValues[0] &&
    axcessValues[0].match(/^(12)\/(31)\/(([0-9]{2})?[0-9]{2})$/g);

  return match === null ? 'No' : 'Yes';
};

const returnFn = ([x]) => x;

const supFinArtExhbRptElectRptPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  /**
   * If first field is 'X', return 'No'.
   * If second field is 'X', return 'Yes'.
   * If both fields null, return null
   * If both fields 'X', return null
   */
  return axcessValues[0] === AXCESS_KEYS.AXCESS_KEY_X && !axcessValues[1]
    ? 'No'
    : axcessValues[1] === AXCESS_KEYS.AXCESS_KEY_X && !axcessValues[0]
    ? 'Yes'
    : null;
};

const YNToRadioPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  return axcessValues[0] === AXCESS_KEYS.AXCESS_KEY_Y
    ? 'Yes'
    : axcessValues[0] === AXCESS_KEYS.AXCESS_KEY_N
    ? 'No'
    : null;
};

const YesNoToRadioPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  return axcessValues[0] === AXCESS_KEYS.AXCESS_KEY_YES
    ? 'Yes'
    : axcessValues[0] === AXCESS_KEYS.AXCESS_KEY_NO
    ? 'No'
    : 'No';
};

const YToRadioPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  return axcessValues[0] === AXCESS_KEYS.AXCESS_KEY_Y ? 'Yes' : null;
};

const checkboxToRadioPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;
  if (!axcessValues[0]) return null;

  return axcessValues[0] === AXCESS_KEYS.AXCESS_KEY_X ? 'Yes' : 'No';
};

const anyValidCheckboxOrFieldToRadioPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  const pulledValue = axcessValues.find((x) => x);
  if (!pulledValue) return null;

  return pulledValue ? 'Yes' : 'No';
};

const greaterThanZeroToRadioPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  const pulledValue = axcessValues
    .map((x) => _.toSafeInteger(x))
    .find((x) => x && x > 0);
  return pulledValue ? 'Yes' : null;
};

const anyValidCheckboxOrFieldToYesNoRadioPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  const pulledValue = axcessValues.find((x) => x);

  return pulledValue ? 'Yes' : 'No';
};

const basicDataForeignProvincePull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;
  if (!axcessValues[0]) return null;

  // Omit if value matches to a selection under basic data canadian provinces
  // GROWTH: Generalize function for all dropdowns
  const lookupItem = LOOKUPS.BASIC_DATA_PROVINCES.find((x) =>
    simpleValueCompare(axcessValues[0])(x)
  );
  return lookupItem ? null : axcessValues[0];
};

const conditionalPullIfFieldMatches = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  return axcessValues[2] && axcessValues[0]
    ? `${axcessValues[0]}`
    : axcessValues[1]
    ? `${axcessValues[1]}`
    : null;
};

const pullFieldToMatchDropDown = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  const index = axcessValues.findIndex((x) => x); // Find the index of the first non-empty element
  const entityType = AXCESS_KEYS[AXCESS_KEYS.REL_ORG_TYPES[index]]; // Grab entity type based on index

  return entityType ? entityType : null; // Null check somewhere if out of bounds
};

const anyValidFilteredPull = (axcessValues, data) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  const axcessValue = axcessValues.find((x) => x);

  return axcessValue ? axcessValue.split(data.charToFilter).join('') : null;
};

const taxExemptBondAboutIssuerLabelPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  let issuerName = axcessValues[0]
    ? `Issuer Name: ${axcessValues[0]}`
    : 'Issuer Name: ';
  let eINumber = axcessValues[1]
    ? `Issuer EIN: ${axcessValues[1]}`
    : 'Issuer EIN: ';
  let CUSIP = axcessValues[2] ? `CUSIP #: ${axcessValues[2]}` : 'CUSIP #: ';

  if (issuerName.length > 20) {
    issuerName = `${issuerName.substring(0, 17)}...`;
  }

  if (eINumber.length > 20) {
    eINumber = `${eINumber.substring(0, 17)}...`;
  }

  if (CUSIP.length > 20) {
    eINumber = `${CUSIP.substring(0, 17)}...`;
  }

  if (!issuerName && !eINumber && !CUSIP) return null;
  return `${issuerName}\n${eINumber}\n${CUSIP}`;
};

const taxExemptBondIssueDetailsLabelPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  let issueDate = axcessValues[0]
    ? `Date Issued: ${axcessValues[0]}`
    : 'Date Issued: ';
  let issuedPrice = axcessValues[1]
    ? `Issued Price: $${axcessValues[1]}`
    : 'Issued Price: ';

  if (!issueDate && !issuedPrice) return null;
  return `${issueDate}\n${issuedPrice}`;
};

const taxExemptBondOtherDetailsLabelPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  let defeased = axcessValues[0] ? 'Defeased: Yes' : 'Defeased: No';
  let onBehalf = axcessValues[1]
    ? 'On Behalf of Issuer: Yes'
    : 'On Behalf of Issuer: No';
  let pooledFinancing = axcessValues[2]
    ? 'Pooled Financing: Yes'
    : 'Pooled Financing: No';

  if (!defeased && !onBehalf && !pooledFinancing) return null;
  return `${defeased}\n${onBehalf}\n${pooledFinancing}`;
};

const incrementTaxYearPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues)) return null;

  const date = moment(axcessValues[0], [ MM_DD_YYYY, YYYY_MM_DD ]);
  date.add(1, 'year');

  return date?.isValid() ? date.format(MM_DD_YYYY) : null;
};

const hosFaPolAppDscPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues) || !axcessValues.length) return null;

  if (axcessValues[0]) return 'Applied uniformly to all hospital facilities';
  if (axcessValues[1]) return 'Applied uniformly to most hospital facilities';
  if (axcessValues[2]) return 'Generally tailored to individual hospital facilities';

  return null;
};

const hosFaIncmLmtFreePull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues) || !axcessValues.length) return null;

  const pulledValue = axcessValues.find(x => x)

  if (!pulledValue) return null;

  switch (pulledValue) {
    case '1':
      return '100%'
    case '2':
      return '150%'
    case '3':
      return '200%'
    default:
      return 'Other'
  }
}
const hosFaIncmLmtDiscPull = (axcessValues) => {
  if (!axcessValues || !_.isArray(axcessValues) || !axcessValues.length) return null;

  const pulledValue = axcessValues.find(x => x)

  if (!pulledValue) return null;

  switch (pulledValue) {
    case '1':
      return '200%'
    case '2':
      return '250%'
    case '3':
      return '300%'
    case '4':
      return '350%'
    case '5':
      return '400%'
    default:
      return 'Other'
  }
}

const aboutHospitalPull = (axcessValues) => {
    if (!axcessValues || !_.isArray(axcessValues)) return null;
  
    let CHNA = axcessValues[0]
      ? `Most recently conducted a CHNA: ${axcessValues[0]}`
      : '';
    let numOfYears = axcessValues[1]
      ? `Number of years since last CHNA: ${axcessValues[1]}`
      : 'Number of years since last CHNA: -';
  
    if (!CHNA) return null;
    return `${CHNA}\n${numOfYears}`;
  };

const fapDetailsPull = (axcessValues) => {
    if (!axcessValues || !_.isArray(axcessValues)) return null;
  
    let fapWebsite = axcessValues[0]
      ? `FAP Website: ${axcessValues[0]}`
      : '';
    let fapPlainLangSumWeb = axcessValues[1]
      ? `FAP Plain Language Summary Website: ${axcessValues[1]}`
      : '';
    let fapAsstAppWeb = axcessValues[2]
      ? `Financial Assistance Application Website: ${axcessValues[2]}`
      : '';
  
    if (!fapWebsite && !fapPlainLangSumWeb && !fapAsstAppWeb) return null;
    return `${fapWebsite}\n${fapPlainLangSumWeb}\n${fapAsstAppWeb}`;
  };

const pyFunctions = {
  iraTypePull,
  iraAmountPull,
  sepTypePull,
  sepAmountPull,
  anyValidPull,
  unemploymentCompTSJPull,
  unemploymentCompDescPull,
  farmCropInsurancePull,
  farmGasFuelPull,
  stateLocalIncomeCityPull,
  stateLocalIncomeStatePull,
  stateLocalTsjPull,
  stateLocalIncomeRefundPull,
  sumAllPull,
  vehicleOtherMilesPull,
  foreignAssetsBankInfoLabelPull,
  foreignAssetsAccountHeldJointlyOther,
  accountTypePull,
  foreignIncomeHomeAddressPull,
  attachOrTablePull,
  foreignIncomeHomeOccupantPull,
  dependentsSummaryPull,
  odtkeCompensationPull,
  odtkeLabelPull,
  taxExemptStatusPull,
  publicCharityStatusPull,
  removeHyphensPull,
  joinTextPull,
  joinTextOnNewLinePull,
  programsMSPull,
  basicDataMSPull,
  govMbrsStockPull,
  fsRptAcctFedReqAudMissExpPull,
  govBodyGovPull,
  govBodyNonGovPull,
  govBodyContExpPull,
  govBodyContCmtePull,
  govBodyWrtnPolLocalChptsExpPull,
  fsRptAcctMthdPYPull,
  gridToMultiselectPull,
  checkboxValuePull,
  fiscalYearPull,
  supFinArtExhbRptElectRptPull,
  YNToRadioPull,
  checkboxToRadioPull,
  anyValidCheckboxOrFieldToRadioPull,
  basicDataForeignProvincePull,
  pullIfFieldMatches,
  anyValidCheckboxOrFieldToYesNoRadioPull,
  conditionalPullIfFieldMatches,
  pullFieldToMatchDropDown,
  greaterThanZeroToRadioPull,
  anyValidFilteredPull,
  checkboxToYNPull,
  taxExemptBondAboutIssuerLabelPull,
  taxExemptBondIssueDetailsLabelPull,
  taxExemptBondOtherDetailsLabelPull,
  YToRadioPull,
  checkboxPull,
  anyValidPercentPull,
  incrementTaxYearPull,
  YesNoToRadioPull,
  hosFaPolAppDscPull,
  hosFaIncmLmtFreePull,
  hosFaIncmLmtDiscPull,
  aboutHospitalPull,
  fapDetailsPull
};

const getPYCalculationFunction = (functionName) => {
  return functionName && pyFunctions[functionName]
    ? pyFunctions[functionName]
    : returnFn;
};

const filterPYByIdentifier = (identifier) => (priorYearData) => {
  if (
    !identifier?.section ||
    !identifier?.id ||
    !priorYearData?.section ||
    !priorYearData?.id
  )
    return false;

  return (
    priorYearData.section === identifier.section &&
    priorYearData.id.startsWith(identifier.id)
  );
};

const filerPYByControl = (controls) => (priorYearData) => {
  if (!controls?.entityId) return true;

  // TODO: set other control properties such as sheetId
  return controls.entityId === priorYearData?.entityId;
};

const findEntityNameFields = (sections) => {
  const entityPriorities = [];

  sections?.forEach((section) => {
    section?.groups?.forEach((group) => {
      group?.fields
        ?.filter((x) => x.isEntityName === true)
        ?.forEach((entityNameField) => {
          entityPriorities.push({
            name: entityNameField?.name || '',
            priority: entityNameField?.entityNamePriority || 0,
            groupId: group.groupId,
            sectionId: section.sectionId,
          });
        });
    });
  });

  return entityPriorities.sort((a, b) => b.priority - a.priority);
};

const matchToLookupOptions = (
  foundValue,
  lookup,
  priorCompareOptions,
  type
) => {
  if (type === FIELD_TYPE.RADIO && !priorCompareOptions?.radioShouldLookup)
    return foundValue === null
      ? AXCESS_KEYS.AXCESS_KEY_NO
      : AXCESS_KEYS.AXCESS_KEY_YES;

  const compareAllLookup = priorCompareOptions?.compareAllLookup ? true : false;
  const isCaseSensitive = priorCompareOptions?.isCaseSensitive ? true : false;
  const compareFn = compareAllLookup ? allCompare : simpleValueCompare;
  const lookups = LOOKUPS[lookup] || LOOKUPS.YESNO;

  let lookupItem = lookups.find((x) =>
    compareFn(foundValue, isCaseSensitive)(x)
  );

  if (lookupItem && isCaseSensitive)
    return lookupItem.value
      .toLowerCase()
      .replace(/(^\w{1})|(\s+\w{1})/g, (firstLetter) =>
        firstLetter.toUpperCase()
      );

  if (type === FIELD_TYPE.MULTISELECT) return lookupItem;

  return lookupItem ? lookupItem.value : '';
};


const findFromPYData = (priorYearEntity, field, fieldName) => {
  const axcessPull = field && field.axcess?.pull;

  const fieldKey = axcessPull?.fields?.[0] || field?.name || fieldName;
  let foundPYData = null;

  priorYearEntity?.forEach((entitySection) => {
    entitySection?.data?.forEach((worksheetSection) => {
      if (foundPYData) return;
      const foundField = worksheetSection?.fields?.find(hasMatchingPYKey(fieldKey));
      const foundLines = worksheetSection?.lineItems?.filter((line) =>
        line?.find((x) => x?.key === fieldKey)
      )

      if (foundField) {
        foundPYData = [foundField]
      } else if (foundLines && !foundPYData) {
  
        const foundLineFields = [];
        foundLines?.forEach((foundLine) => {
          const matchingLine = foundLine.find((x) => x?.key === fieldKey)
          if (matchingLine) foundLineFields.push(matchingLine) 
        });

        if (foundLineFields.length) {
          foundPYData = foundLineFields;
        }
      }
    });
  });

  const foundValue = axcessPull?.fn([foundPYData]) || foundPYData;

  if (field?.type === 'select') {
    return matchToLookupOptions(
      foundValue,
      field.lookup,
      field?.priorCompareOptions
    );
  }

  return foundPYData;
};

const findFromData = (worksheetSection, key, searchType) => {
  if (searchType === 'fields') {
    return [findFieldInLine(worksheetSection?.fields, key)].filter((x) => x);
  } else if (searchType === 'lineItems') {
    const a = worksheetSection?.lineItems
      ?.map((line) => {
        return line.find((x) => x.key === key);
      })
      .filter((x) => x);
    return a;
  }

  return [];
};

const findSection = (sections, sectionToFind) => {
  return sections?.find((x) => x.name === sectionToFind);
};

const filterSections = (sections, sectionToFilter) => {
  return sections?.filter((x) => x.name === sectionToFilter);
};

const findFieldInLine = (field, key) => {
  return field?.find(hasMatchingPYKey(key));
};

const hasMatchingPYKey = (key) => (field) => {
  return field?.key === key;
};

const simpleValueCompare = (value) => (compareObj) =>
  simpleCompare(value)(compareObj?.value);

const allCompare = (value, isCaseSensitive) => (compareObj) => {
  return Object.keys(compareObj).some((compareKey) => {
    if (_.isArray(compareObj[compareKey])) {
      return isCaseSensitive
        ? compareObj[compareKey.toLowerCase()].some(
            simpleCompare(value.toLowerCase())
          )
        : compareObj[compareKey].some(simpleCompare(value));
    }
    return isCaseSensitive
      ? simpleCompare(value.toLowerCase())(compareObj[compareKey].toLowerCase())
      : simpleCompare(value)(compareObj[compareKey]);
  });
};

const simpleCompare = (value) => (compareValue) => compareValue === value;

const findMergeData = (priorYearData, mergeDataIdentifiers) => {
  const foundMergeData = mergeDataIdentifiers.map((mergeDataIdentifier) => {
    const filteredMergeData = priorYearData.filter(
      filterPYByIdentifier(mergeDataIdentifier)
    );

    const { mergeType, key } = mergeDataIdentifier;
    
    const foundPYData = findFromPYData(filteredMergeData, {
      name: key
    });

    return (foundPYData?.length)
      ? { mergeType, mergingData: foundPYData }
      : null;
  });

  return foundMergeData;
};

const getPullFieldValues = (priorYearData) => (key) => {
  const foundPriorYearItem = priorYearData?.find(hasMatchingPYKey(key));
  return foundPriorYearItem?.value || null;
};

const hsaTablePopulate = (group, metadata) => {
  const sumOnColumns = metadata?.sumOnColumns || [];

  const sumHolder = {
    [AXCESS_KEYS.TAXPAYER_KEY]: {},
    [AXCESS_KEYS.SPOUSE_KEY]: {},
  };
  const rows = _.cloneDeep(group?.lineItems) || [];
  const filterForValue = (valueTS) => (row) =>
    row.some((column) => column?.default === valueTS);
  const sumOnRow = (valueTS) => (row) => {
    sumOnColumns.forEach((columnKey) => {
      const foundColumn = row.find((x) => x.name === columnKey);
      if (foundColumn && foundColumn.default) {
        if (sumHolder[valueTS]?.[columnKey]) {
          sumHolder[valueTS][columnKey] += parseMoneyToNumber(
            foundColumn.default
          );
        } else {
          sumHolder[valueTS][columnKey] = parseMoneyToNumber(
            foundColumn.default
          );
        }
      }
    });
  };

  // Build new row with first column value
  const buildNewRow = (valueTS) => {
    const row = _.cloneDeep(group.fields);
    row[0].default = valueTS;
    row[0].inputState = INPUT_STATE.PRIOR_INPUT;

    row.forEach((column) => {
      if (_.has(sumHolder[valueTS], column.name)) {
        column.inputState = INPUT_STATE.PRIOR_INPUT;
        column.default =
          column.isMoney === false
            ? noDollarFormatter(sumHolder[valueTS][column.name])
            : moneyFormatter(sumHolder[valueTS][column.name]);
      }
    });

    return row;
  };

  // Grab first available Taxpayer and Spouse row
  rows
    .filter(filterForValue(AXCESS_KEYS.TAXPAYER_KEY))
    .forEach(sumOnRow(AXCESS_KEYS.TAXPAYER_KEY));
  rows
    .filter(filterForValue(AXCESS_KEYS.SPOUSE_KEY))
    .forEach(sumOnRow(AXCESS_KEYS.SPOUSE_KEY));

  const results = [];
  // Add the taxpayer row first, and then spouse row. Build row if not available
  results.push(buildNewRow(AXCESS_KEYS.TAXPAYER_KEY));
  results.push(buildNewRow(AXCESS_KEYS.SPOUSE_KEY));

  group.lineItems = results;
};

const getFieldObject = (currentFormSections, fieldLocation) => {
  if (!currentFormSections || !fieldLocation) return null;

  const section = currentFormSections?.find(
    (section) => section.sectionId === fieldLocation.sectionId
  );
  const group = section?.groups?.find(
    (group) => group.groupId === fieldLocation.groupId
  );
  const field = group?.fields.find(
    (field) => field.name === fieldLocation.name
  );

  return field;
};

const matchToRadioLookupOptions = (foundValue) => {
  const lookupItem =
    foundValue === null
      ? AXCESS_KEYS.AXCESS_KEY_NO
      : AXCESS_KEYS.AXCESS_KEY_YES;

  return lookupItem;
};
const getFieldData = (datas) => {
  for (const data of datas) return data.data;
};

const getRequiredPSData = (sectionId, priorYearData, lookUp) => {
  const filteredData = priorYearData?.filter(
    (data) => data && data.id === sectionId
  );

  const statements = [];
  let statement = {};
  let key;
  const fieldArray = getFieldData(filteredData)?.map((x) => x.fields);

  // The is assuming that the order is important and has to be in that particular shape
  fieldArray?.forEach((field) => {
    if (lookUp && field[0]?.value === lookUp[0]) {
      key = field[1]?.key;
      statements.push(field[1]?.value);
    }
  });

  if (key !== undefined) {
    statement.key = key;
    statement.value = statements.join('\n');
  }

  return statement;
};

export {
  foreignCountryPull,
  foreignAccountTypePull,
  americanStatesPull,
  canadianProvincePull,
  mexicanStatesPull,
  foreignProvincePull,
  datePull,
  jointToTaxpayerPull,
  checkboxPull,
  checkboxToYNPull,
  withdrawalPull,
  maritalStatusPull,
  foreignAssetsIdTypePull,
  translateFunctions,
  translatePullData,
  iraTypePull,
  accountTypePull,
  unemploymentCompDescPull,
  unemploymentCompTSJPull,
  stateLocalIncomeCityPull,
  stateLocalIncomeStatePull,
  stateLocalTsjPull,
  stateLocalIncomeRefundPull,
  iraAmountPull,
  sepTypePull,
  sepAmountPull,
  farmCropInsurancePull,
  farmGasFuelPull,
  anyValidPull,
  sumAllPull,
  vehicleOtherMilesPull,
  foreignAssetsBankInfoLabelPull,
  foreignAssetsAccountHeldJointlyOther,
  foreignIncomeHomeAddressPull,
  foreignIncomeHomeOccupantPull,
  dependentsSummaryPull,
  odtkeCompensationPull,
  odtkeLabelPull,
  taxExemptStatusPull,
  publicCharityStatusPull,
  removeHyphensPull,
  joinTextPull,
  joinTextOnNewLinePull,
  programsMSPull,
  basicDataMSPull,
  govMbrsStockPull,
  govBodyGovPull,
  govBodyNonGovPull,
  govBodyContExpPull,
  govBodyContCmtePull,
  fsRptAcctFedReqAudMissExpPull,
  fsRptAcctMthdPYPull,
  govBodyWrtnPolLocalChptsExpPull,
  pyFunctions,
  getPYCalculationFunction,
  filterPYByIdentifier,
  filerPYByControl,
  findEntityNameFields,
  findFromPYData,
  findSection,
  filterSections,
  findFieldInLine,
  findFromData,
  childCareExpensesPull,
  simpleValueCompare,
  allCompare,
  matchToLookupOptions,
  zipPostalPull,
  findMergeData,
  hasMatchingPYKey,
  getPullFieldValues,
  hsaTablePopulate,
  getFieldObject,
  matchToRadioLookupOptions,
  getRequiredPSData,
  supFinArtExhbRptElectRptPull,
  YNToRadioPull,
  basicDataForeignProvincePull,
  anyValidCheckboxOrFieldToYesNoRadioPull,
  pullFieldToMatchDropDown,
  anyValidFilteredPull,
  simpleCompare,
  taxExemptBondAboutIssuerLabelPull,
  taxExemptBondIssueDetailsLabelPull,
  taxExemptBondOtherDetailsLabelPull,
  YToRadioPull,
  anyValidPercentPull,
  YesNoToRadioPull,
  hosFaPolAppDscPull,
  hosFaIncmLmtFreePull,
  hosFaIncmLmtDiscPull,
  incrementTaxYearPull
};
