import _ from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector, useStore } from "react-redux";
import moment from "moment";
import * as INPUTSTATE from "./../constants/inputState";
import * as REDUX from "./../constants/redux";
import * as NAV from "./../constants/navigation";
import * as CARDSTATUS from "./../constants/cardStatus";
import * as SERVER from "./../constants/server";
import * as ACTION from "./../actions/global";
import * as Validators from "./../components/formRenderer/fields/utils/Validators";
import * as STRING from "./../constants/strings";

import acceptedFileChecker from "./../utils/acceptedFileChecker";

import { selectReduxGlobalState } from "./../utils/store";
import { parseFormData } from "./../utils/formDataParser";

// let currentPayload = null;
// let currentCardStatus = null;
let client;
let claToken = null;

export const currentYear = 2023;
const appType = '990';

const getData = (query, token) => {
  const options = {
    method: "GET",
    headers: { "cla-app-token": claToken ? claToken : token },
  };

  return fetch(`api/${query}`, options)
    .then((response) => response.json())
    .then((results) => results);
};

const postData = (query, body, token) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "cla-app-token": claToken ? claToken : token,
    },
    body: JSON.stringify(body),
  };

  return fetch(`api/${query}`, options)
    .then((response) => response.json())
    .then((result) => result)
    .catch((err) => {
      console.error("Error:", err);
      return null;
    });
};

const deleteData = (query, json, token) => {
  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "cla-app-token": claToken ? claToken : token,
    },
    body: JSON.stringify(json),
  };

  return fetch(`api/${query}`, options)
    .then((response) => response.json())
    .then((result) => result)
    .catch((err) => {
      console.error("Error:", err);
      return null;
    });
};

let currentEntity = {};

// only used by components produced by storybook
function usePageFramework(page) {
  let card = null;

  client = useSelector((state) =>
    selectReduxGlobalState(state, REDUX.CURRENT_USER)
  );
  claToken = useSelector((state) =>
    selectReduxGlobalState(state, REDUX.CLA_TOKEN)
  );
  const authUser = useSelector((state) =>
    selectReduxGlobalState(state, REDUX.AUTH_USER)
  );
  const dashboard = useSelector((state) =>
    selectReduxGlobalState(state, REDUX.DASHBOARD)
  );
  const currentCardKey = useSelector((state) =>
    selectReduxGlobalState(state, REDUX.CURRENT_CARD_KEY)
  );
  const screenSize = useSelector((state) =>
    selectReduxGlobalState(state, REDUX.SCREEN_SIZE)
  );
  const uploadList = useSelector((state) =>
    selectReduxGlobalState(state, REDUX.UPLOAD_LIST)
  );
  const catCardProp = page ? "route" : "key";
  const catCardCompare = page ? page : currentCardKey;

  // category.cards.find fails on test because cards can be empty on load
  dashboard?.findIndex(
    (category) =>
      category.cards &&
      category.cards.find((catCard) => {
        const isFound = catCard[catCardProp] === catCardCompare;
        if (isFound) {
          card = catCard;
        }
        return isFound;
      })
  );

  const updateStatusCard = (statusId, dashCard) => {
    card = dashCard;
    updateStatus(statusId);
  };

  const updateStatus = (statusId) => {
    dispatch(ACTION.setCard(card));

    if (card) {
      card.statusId = statusId;
      card.statusTitle = CARDSTATUS.STATUS_TITLE[statusId];

      dispatch(ACTION.setDashboard(dashboard));
      if (statusId === CARDSTATUS.DOES_NOT_APPLY) {
        dispatch(ACTION.setIsCurrentFormNa(true));
      } else dispatch(ACTION.setIsCurrentFormNa(false));

      const json = {
        year: currentYear,
        formName: card.formName,
        statusId,
        dashboard,
      };
      postData("taxFormStatus", json, claToken);
    }
  };

  const updateCardNotes = (formNotes, cardForNotes) => {
    dispatch(ACTION.setCard(cardForNotes));

    if (cardForNotes) {
      cardForNotes.notes = _.cloneDeep(formNotes);
      dispatch(ACTION.setDashboard(dashboard));
    }
  };

  const updateVisibleDashboardCards = (filterstatusId) =>
    dispatch(ACTION.setDashboardFilterStatus(filterstatusId));
  const updateSortedDashboardCards = (sortBy) =>
    dispatch(ACTION.setDashboardSortRequirements(sortBy));
  const selectState = (stateLabel) =>
    useSelector((state) => selectReduxGlobalState(state, stateLabel));
  let currentCardStatus = selectState(REDUX.CURRENT_CARD_STATUS);
  let currentPayload = null;

  const setGlobalFormState = (formName, payload) => {
    const parsedFormData = parseFormData(payload);
    currentEntity[formName] = payload;
    dispatch(ACTION.setForm(formName, parsedFormData));

    const isEntityForm =
      _.startsWith(formName, REDUX.BUSINESS_INCOME) ||
      _.startsWith(formName, REDUX.RENTAL_INCOME) ||
      _.startsWith(formName, REDUX.FARM_INCOME);
    const statusId =
      card && card.statusId ? card.statusId : CARDSTATUS.NOT_STARTED;
    currentPayload = { formName, payload: parsedFormData, statusId };
    const cardStatusId = currentCardStatus || statusId;

    const json = {
      year: currentYear,
      formName,
      form: parsedFormData,
      statusId: cardStatusId,
    };
    postData("taxForm", json, claToken);

    if (isEntityForm && card.statusId !== CARDSTATUS.COMPLETED)
      updateStatusCard(statusId, card);
  };

  const setEntityFormState = (formName, payload) => {
    dispatch(ACTION.setForm(formName, payload));
    currentEntity[formName] = payload;
    const json = {
      year: currentYear,
      formName,
      form: payload,
      statusId: card && card.statusId ? card.statusId : CARDSTATUS.NOT_STARTED,
    };
    postData("taxForm", json, claToken);
  };

  const setMultiFormState = (multiFormState) => {
    if (!multiFormState || !_.isPlainObject(multiFormState)) return;

    Object.keys(multiFormState).forEach((key) => {
      dispatch(ACTION.setForm(key, multiFormState[key]));
    });

    const json = {
      year: currentYear,
      forms: multiFormState,
      statusId: card && card.statusId ? card.statusId : CARDSTATUS.NOT_STARTED,
    };
    postData("multiTaxForm", json, claToken);
  };

  const updatePage = (pageInfo) => {
    dispatch(ACTION.setToolbarTitle(pageInfo.title));
    dispatch(ACTION.showFormButtonMenu(false));
    dispatch(ACTION.setCurrentCardKey(pageInfo.key));
    dispatch(ACTION.showBulkUpload(false));
    dispatch(ACTION.setDashboardFilterStatus(-1));
    dispatch(ACTION.setDashboardSortRequirements("traditional"));
    dispatch(ACTION.setDashboardNotes([]));
    dispatch(ACTION.setUploadList(null));
  };

  const updateCard = () => {
    if (card) {
      dispatch(ACTION.setToolbarTitle(card.title));
      dispatch(ACTION.showFormButtonMenu(true));
      dispatch(ACTION.setCurrentCardKey(card.key));
      dispatch(ACTION.setCurrentCardStatus(null));

      if (card.statusId === CARDSTATUS.DOES_NOT_APPLY) {
        dispatch(ACTION.setIsCurrentFormNa(true));
      } else dispatch(ACTION.setIsCurrentFormNa(false));

      if (card.showBulkUpload) {
        dispatch(ACTION.showBulkUpload(true));
        card.bulkUpload.isLoaded = false;
        card.bulkUpload.expanded = false;
        card.bulkUpload.uploadList = [];
        card.bulkUpload.buttonState = "active";
        dispatch(ACTION.setDashboard(dashboard));
      } else {
        dispatch(ACTION.showBulkUpload(false));
      }
    } else {
      dispatch(ACTION.showFormButtonMenu(false));
      dispatch(ACTION.showBulkUpload(true));
      dispatch(ACTION.setCurrentCardKey(STRING.DASHBOARD_KEY));
      dispatch(ACTION.setIsCurrentFormNa(false));
    }
  };

  const history = useHistory();
  const dispatch = useDispatch();
  const store = useStore();
  const location = useLocation();

  const clearFormState = () => {
    const state = store.getState();
    state.get("global").forEach((value, key) => {
      if (!key.startsWith("G_") && !key.startsWith("@")) {
        // Only Keep Global Variables from being cleared!
        dispatch({ type: key, payload: null });
      }
    });
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const onFileUpload = (event, acceptedFiles, section, list, fn, dashKey) => {
    dispatch(
      ACTION.setCustomDialogMsg(
        "Accepted files are .png, .jpeg, .jpg, .pdf, .doc, .docx, .xls, .xlsx, .text, .txt"
      )
    );
    dispatch(ACTION.setCustomDialogTitle("File type not accepted"));
    const filesToUpload = acceptedFiles ? acceptedFiles : event.target.files;
    let resFiles = [];
    let files = [];
    const year = 2021; // TODO: Get from client Details
    let pattern = /[\/:*?"<>|/\\]/g;
    let invalidFiles = [];
    dispatch(ACTION.setProgressText("Uploading..."));

    if (!filesToUpload) return;

    dispatch(ACTION.setProgressVisible(true));
    dispatch(ACTION.setUploadProgressVisible(true));

    for (let i = 0; i < filesToUpload.length; i++) {
      let matched = filesToUpload[i].name.match(pattern);

      if (matched !== null) {
        invalidFiles.push(filesToUpload[i].name);
        dispatch(ACTION.setProgressVisible(false));
        dispatch(ACTION.setCustomDialogTitle(""));
        dispatch(ACTION.setShowCustomDialog(true));
        dispatch(
          ACTION.setCustomDialogMsg(
            <>
              <h3>
                Files listed below have invalid names. Please fix and reupload:
              </h3>
              <h4>
                A file name can't contain any of the following characters:{" "}
                <br />\ / : * ? " {"< >"} |
              </h4>
              <div className="invalidFilesContainer">
                {invalidFiles.map((file, idx) => (
                  <li key={idx} id={idx}>
                    {file}
                  </li>
                ))}
              </div>
            </>
          )
        );
        continue;
      }

      if (!acceptedFileChecker(filesToUpload[i])) {
        dispatch(ACTION.setShowCustomDialog(true));
        dispatch(ACTION.setProgressVisible(false));
        dispatch(ACTION.setUploadProgressVisible(false));
        continue;
      }

      const formData = new FormData();
      formData.append("file", filesToUpload[i]);
      formData.append("section", section);
      formData.append("cardKey", dashKey ? dashKey : currentCardKey);
      formData.append("year", year);

      const { name } = filesToUpload[i];
      const updated_on = moment(new Date()).format("MMM DD, YYYY");
      const extension = filesToUpload[i].name.split(".").pop();
      const type = filesToUpload[i].type;
      const uploaded_by = authUser.displayName;
      const file = {
        section,
        name,
        updated_on,
        extension,
        upload_id: -1,
        type,
        uploaded_by,
        blob_location: "",
      };
      list.push(file);
      files.push(file);
      dispatch(ACTION.setUploadList(list));

      const options = {
        method: "POST",
        headers: { "cla-app-token": claToken },
        body: formData,
      };

      fetch("api/file", options)
        .then((response) => response.json())
        .then((result) => {
          file.upload_id = result.uploadId;
          file.blob_location = result.blob_location;
          resFiles.push(result);

          if (resFiles.length === files.length) {
            dispatch(ACTION.setProgressVisible(false));
            dispatch(ACTION.setUploadProgressVisible(false));
            dispatch(ACTION.setUploadList(list));
          }

          fn ? fn.updateState() : () => {};
          updateStatus(CARDSTATUS.IN_PROGRESS);
        })
        .catch((error) => {
          console.error("Error:", error);
          dispatch(ACTION.setProgressVisible(false));
          dispatch(ACTION.setUploadProgressVisible(false));
          list.pop();
          dispatch(ACTION.setUploadList(list));
          dispatch(ACTION.setCustomDialogTitle("Upload Error!"));
          dispatch(ACTION.setCustomDialogMsg("Please try again later."));
          dispatch(ACTION.setShowCustomDialog(true));
        });
    }
    // dispatch(ACTION.setUploadProgressVisible(false));
  };

  const checkGroup = (group, errorCount) => {
    if (group.lineItems) {
      group.lineItems.forEach((lineItem) => {
        lineItem.forEach((field) => {
          if (field.error === true) {
            // errors[field.name] = field.errorMessage;
            errorCount++;
          }
        });
      });
    }
    if (group.fields) {
      group.fields.forEach((field) => {
        if (field.error === true) {
          // errors[field.name] = field.errorMessage;
          errorCount++;
        }
      });
    }
    //for dense row line sections / entities
    if (group.entities && group.entities.length) {
      group.entities.forEach((entity) => {
        if (entity.sections && entity.sections.length) {
          entity.sections.forEach((section) => {
            if (section.groups && section.groups.length) {
              section.groups.forEach((group) => {
                errorCount = checkGroup(group, errorCount);
              });
            }
          });
        }
      });
    }
    return errorCount;
  };

  const formState = selectState(card ? card.formName : null);
  const validateCurrentCard = () => {
    // Determine whether or not there are fields with errors for this card
    // @TODO how are we calculating a card statusId once errors are resolved?
    // @TODO will users be able to edit the card in a complete state? Should I update error status on card if in complete state?
    // @TODO move to usePageFramework?
    let errors = {};
    let errorCount = 0;
    //meet.google.com/kym-qyth-upj
    https: if (formState && card.statusId !== CARDSTATUS.COMPLETED) {
      formState.forEach((section) => {
        if (section && section.length > 0) {
          section.forEach((eachSection) => {
            if (eachSection.groups) {
              eachSection.groups.forEach((group) => {
                errorCount = checkGroup(group, errorCount);
              });
            }
          });
        } else if (section.groups) {
          section.groups.forEach((group) => {
            errorCount = checkGroup(group, errorCount);
          });
        } else {
          errorCount = checkGroup(section, errorCount);
        }
      });
      for (const key in currentEntity) {
        if (Object.hasOwnProperty.call(currentEntity, key)) {
          const section = currentEntity[key];
          if (section && section.length > 0) {
            section.forEach((eachSection) => {
              if (eachSection.groups) {
                eachSection.groups.forEach((group) => {
                  errorCount = checkGroup(group, errorCount);
                });
              }
            });
          } else if (section.groups) {
            section.groups.forEach((group) => {
              errorCount = checkGroup(group, errorCount);
            });
          } else {
            errorCount = checkGroup(section, errorCount);
          }
        }
      }
      currentEntity = {};
      if (errorCount > 0) {
        // ERRORS
        updateStatus(CARDSTATUS.ERRORS);
      }
      if (card.statusId === CARDSTATUS.ERRORS && errorCount === 0) {
        updateStatus(CARDSTATUS.IN_PROGRESS);
      }
    }
  };

  return {
    page,
    card,
    currentCardKey,
    clearFormState,
    client,
    history,
    dashboard,
    dispatch,
    screenSize,
    selectState,
    setGlobalFormState,
    setEntityFormState,
    setMultiFormState,
    location,
    uploadList,
    updateCard,
    updatePage,
    updateStatusCard,
    updateStatus,
    updateVisibleDashboardCards,
    updateSortedDashboardCards,
    updateCardNotes,
    onFileUpload,
    REDUX,
    NAV,
    ACTION,
    CARDSTATUS,
    SERVER,
    INPUTSTATE,
    Validators,
    postData,
    getData,
    deleteData,
    validateCurrentCard,
    appType
  };
}

export default usePageFramework;

export const frameworkSetter = {
  appFramework: null,
  set framework(value) {
    this.appFramework = value;
  },
  get usePageFramework() {
    if (this.appFramework) {
      return this.appFramework;
    }
  }
}
