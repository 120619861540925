import React, { useState, useEffect } from 'react';
import { Card, DropdownMenu, MenuItem, useToggle } from 'react-md';
import { TOP_LEFT_ANCHOR } from '@react-md/utils';
import { MoreVertSVGIcon } from '@react-md/material-icons';

import './headerImage.css'
import SubmitDialog from '@components/SubmitDialog';
import api from '@utilities/claApi';
import usePageFramework from '@utilities/hooks/usePageFramework';
import * as CARDSTATUS from '@utilities/constants/cardStatus';
import * as STRING from '@utilities/constants/strings';
import * as IMG from '@utilities/constants/images';
import { isAllFormFieldsValued } from '@utilities/formData/index.js';

function HeaderImage() {
    const { history,
        selectState,
        REDUX,
        ACTION,
        NAV,
        dispatch,
        card,
        updateStatus,
        validateCurrentCard,
        location
    } = usePageFramework();
    const title = selectState(REDUX.TOOLBAR_TITLE);
    const taxYear = selectState(REDUX.YEAR);
    const organizerId = selectState(REDUX.ORGANIZER_ID)
    const activeClient = selectState(REDUX.ACTIVE_RETURN);
    const isFormLocked = selectState(REDUX.LOCK_FORMS);
    const entryInterviewForm = Array.from(selectState(REDUX.ENTRY_INTERVIEW) || []);

    const isDashboard = title === STRING.DASHBOARD || location?.pathname?.includes('/dashboard');
    const cardSubHeader = `${taxYear} ${STRING.TAXES}`;
    const companyName = activeClient?.displayName;
    const [completeCardStyles, setCompleteCardStyles] = useState(null);
    const [visibleWarningDialog, showWarningDialog, hideWarningDialog] = useToggle(false);
    const [visibleCompletedWarningDialog, showCompletedWarningDialog, hideCompletedWarningDialog] = useToggle(false);

    const isSubmittedDashboard = isDashboard && (completeCardStyles || activeClient?.formStatus === "Client Submitted");
    const isUnsubmittedDashboard = isDashboard && !isSubmittedDashboard;
    const isCompletedCard = !isDashboard && card?.statusId === CARDSTATUS.COMPLETED;
    const isUncompletedBasicCard = !isDashboard && card?.statusId !== CARDSTATUS.COMPLETED && card?.formName !== REDUX.INTERNAL_SETUP;
    const isUncompletedISCard = !isDashboard && card?.statusId !== CARDSTATUS.COMPLETED && card?.formName === REDUX.INTERNAL_SETUP;

    useEffect(() => {
        if (isFormLocked) {
            if (card?.statusId === CARDSTATUS.COMPLETED) {
                setCompleteCardStyles({ border: '3px solid #0BBA46', opacity: 0.5, cursor: 'not-allowed' });
            } else {
                setCompleteCardStyles({ opacity: 0.5, cursor: 'not-allowed' });
            }
        } else {
            if (card?.statusId === CARDSTATUS.COMPLETED) {
                setCompleteCardStyles({ border: '3px solid #0BBA46' });
            } else {
                setCompleteCardStyles(null);
            }
        }

    }, [card, isFormLocked]);

    const showDialogAndGoBack = (isCancel) => {
        dispatch(ACTION.setShowCustomDialog(true));
        dispatch(ACTION.setCustomDialogTitle(''));

        if (title === STRING.INTERNAL_SETUP && isCancel) {
            dispatch(ACTION.setCustomDialogMsg("Internal Setup Card will remain In Progress and no welcome email will be sent to the client."));
        } else {
            dispatch(ACTION.setCustomDialogMsg("Great work!  We're bringing you back to the dashboard now."));
        }

        dispatch(ACTION.setHideCustomDialogCloseButton(true));
        setTimeout(() => {
            dispatch(ACTION.setShowCustomDialog(false));
            dispatch(ACTION.setCustomDialogTitle(''));
            dispatch(ACTION.setCustomDialogMsg(''));
            dispatch(ACTION.setHideCustomDialogCloseButton(false));
            goBack();
        }, 3000);
    };

    const onSubmitError = () => {
        dispatch(ACTION.setProgressVisible(false));
        dispatch(ACTION.setShowCustomDialog(true));
        dispatch(ACTION.setCustomDialogTitle('Error!'));
        dispatch(ACTION.setCustomDialogMsg('Unsuccessfully Sent! Please try again or contact CLA.'));
        setCompleteCardStyles(null);
    };

    const onSendClick = () => {
        dispatch(ACTION.setProgressText('Sending to CLA...'));
        dispatch(ACTION.setProgressVisible(true));
        const notifData = { type: STRING.ORGANIZER_SUBMITTED, data: { id: organizerId } };

        api.post('/notifications', notifData).then(() => {
            api.put(`/organizers/${organizerId}`, { status: STRING.CLIENT_SUBMITTED_TEXT, lastUserActivityOn: new Date() }).then((response) => {
                dispatch(ACTION.setActiveReturn({ ...activeClient, formStatus: STRING.CLIENT_SUBMITTED_TEXT }));
                dispatch(ACTION.setProgressVisible(false));
                dispatch(ACTION.setShowCustomDialog(true));
                dispatch(ACTION.setCustomDialogTitle('Congratulations!'));
                dispatch(ACTION.setCustomDialogMsg(<React.Fragment>Successfully sent to CLA! We will get started on your return and reach out to you if we need any additional information. Please take 2 minutes to fill out <a className="successSurveyCls" href="https://survey.usabilla.com/live/s/647e3d9be5f4236c513a3fe1">this survey</a> to provide feedback on your experience using the 990 Exchange.</React.Fragment>));
                setCompleteCardStyles({ border: '3px solid #0BBA46' });
            }).catch((error) => {
                console.log(error);
                onSubmitError();
            });
        }).catch((error) => {
            console.log(error);
            onSubmitError();
        });
    };

    const sendWelcomeEmailToUsers = () => {
        api.get(`/organizers/${organizerId}/users`).then((response) => {
            return response.data.results // returns array of users
        }).then((users) => {
            return users.filter(user => user.id && user.email && !user.email.endsWith('@claconnect.com'));
        }).then((externalUsers) => {
            // send the cdh welcome email per user
            const emailPromises = externalUsers.map((externalUser) => {
                return api.put(`/organizers/${organizerId}/users/${externalUser.id}/invite`);
            });

            return Promise.allSettled(emailPromises);
        }).then((results) => {
            const emailsFailed = results.filter((result) => result.status === 'rejected');

            if (emailsFailed.length) {
                console.log(`Failed to send ${emailsFailed.length} welcome email(s)`);
            }
        }).then(() => {
            return api.put(`/organizers/${organizerId}`, { status: STRING.WITH_CLIENT_TEXT, lastUserActivityOn: new Date() });
        }).then((response) => {
            dispatch(ACTION.setActiveReturn({ ...activeClient, formStatus: STRING.WITH_CLIENT_TEXT }));
            dispatch(ACTION.setShowCustomDialog(true));
            dispatch(ACTION.setCustomDialogTitle('Congratulations!'));
            dispatch(ACTION.setCustomDialogMsg('Request sent to CDH for Email Invite'));
            setCompleteCardStyles({ border: '3px solid #0BBA46' });
            updateStatus(CARDSTATUS.COMPLETED);
            dispatch(ACTION.setCurrentCardStatus(CARDSTATUS.COMPLETED));
        }).catch((error) => {
            console.log(error);
            dispatch(ACTION.setShowCustomDialog(true));
            dispatch(ACTION.setCustomDialogTitle('An error has occured'));
            dispatch(ACTION.setCustomDialogMsg('Please contact IT for support'));
        }).finally(() => {
            dispatch(ACTION.setProgressVisible(false));
        });
    }

    const goBack = () => {
        validateCurrentCard();
        dispatch(ACTION.setCurrentCardKey(STRING.DASHBOARD_KEY));
        dispatch(ACTION.setDashboardFilterStatus(CARDSTATUS.REQUIRED));
        dispatch(ACTION.setDashboardSortRequirements('traditional'));
        history.push(`/${organizerId}${NAV.DASHBOARD}`);
    };

    const setCompletedOnClick = () => {
        if (card?.statusId === CARDSTATUS.COMPLETED) {
            updateStatus(CARDSTATUS.IN_PROGRESS);
            dispatch(ACTION.setCurrentCardStatus(CARDSTATUS.IN_PROGRESS));
            setCompleteCardStyles(null);
        } else {
            // Check for if the form is the Internal Setup form
            if (title === STRING.INTERNAL_SETUP) {
                showCompletedWarningDialog();
            } else {
                updateStatus(CARDSTATUS.COMPLETED);
                dispatch(ACTION.setCurrentCardStatus(CARDSTATUS.COMPLETED));
                setCompleteCardStyles({ border: '3px solid #0BBA46' });
                showDialogAndGoBack();
            }
        }
    };

    const HeaderTitle = () => {
        //TODO: Check for practitioner's view, what need to show?
        return (
            <div className="clientInfoHeaderContainer">
                <div className='dashboardTitle'>
                    <p id="headerInfo">
                        {isDashboard ? STRING.EXCHANGE_990 : title}
                    </p>
                </div>
                <div className='dashboardTitle'>
                    <p id="clientId">
                        {isDashboard ? companyName ?? '' : title === "990 Exchange Manager" ? '' : cardSubHeader}
                    </p>
                </div>
            </div>
        );
    };

    const onConfirmWarning = () => {
        onSendClick();
        hideWarningDialog();
    };

    const onConfirmCompletedWarning = () => {
        hideCompletedWarningDialog();
        dispatch(ACTION.setProgressText('Sending welcome invitations...'));
        dispatch(ACTION.setProgressVisible(true));
        sendWelcomeEmailToUsers();
    };

    const onCancelCompletedWarning = () => {
        hideCompletedWarningDialog();
        showDialogAndGoBack(true);
    };

    const onEntryExpWarningClose = () => {
        dispatch(ACTION.setShowCustomDialog(false));
        // Nav to modal url, modal pop
        history.push(NAV.ENTRY_INTERVIEW)
    };

    const checkEntryInterviewForm = () => {
        if (isAllFormFieldsValued(entryInterviewForm)) {
            showWarningDialog();
        } else {
            dispatch(ACTION.setShowCustomDialog(true));
            dispatch(ACTION.setCustomDialogTitle(''));
            dispatch(ACTION.setCustomDialogMsg(
                <React.Fragment>
                    Please return to <span className="entryExpLink" onClick={() => onEntryExpWarningClose()}>Entry Experience</span>, there are questions missing answers.  These questions must be complete before the form can be submitted.
                </React.Fragment>
            ));
        }
    };


    return (
        <>
            <div className="imgContainer">
                <HeaderTitle />
                {title === STRING.EXCHANGE_MANAGER_990 || !activeClient ? null :
                    <div className="dashboardHeaderCardsContainer">
                        <div className="headerImgCardsContainer" style={{ display: 'flex' }}>
                            <Card
                                style={isDashboard && activeClient?.formStatus === "Client Submitted" ? { border: '3px solid #0BBA46' } : completeCardStyles}
                                className="dashboardHeaderIconCard entryNoShadow dashboardHeaderCard"
                                onClick={
                                    isFormLocked || (isDashboard && activeClient?.formStatus === "Client Submitted")
                                        ? () => { }
                                        : !isDashboard
                                            ? card?.statusId === CARDSTATUS.DOES_NOT_APPLY || (card?.formName === REDUX.INTERNAL_SETUP && card?.statusId === CARDSTATUS.COMPLETED)
                                                ? () => { }
                                                : () => setCompletedOnClick()
                                            : () => checkEntryInterviewForm()
                                }
                            >
                                <div className="dashboardHeaderIconName headerCardsPadding" data-testid="dashboard-header-icon-name">
                                    {
                                        isUnsubmittedDashboard ?
                                        <img className="dashboardHeaderIcon" alt='complete-button' src={IMG.COMPLETE_ICON_NORMAL} />
                                        : isSubmittedDashboard || isCompletedCard ?
                                        <img className="dashboardHeaderIcon" alt='complete-button' src={IMG.COMPLETE_ICON_ACTIVE} />
                                        : null
                                    }
                                    {
                                        isDashboard ?
                                        <p>{STRING.SUBMIT_TEXT}</p>
                                        : isUncompletedBasicCard ?
                                        <p>{STRING.MARK_CARD_COMPLETE}</p>
                                        : isUncompletedISCard ?
                                        <p>{STRING.SEND_WELCOME_EMAIL}</p>
                                        : isCompletedCard ?
                                        <p>{STRING.COMPLETED}</p>
                                        : null
                                    }
                                </div>
                            </Card>
                        </div>
                        <div className="headerCardMoreBtnContainer">
                            <DropdownMenu
                                id="headerImgEllipsis"
                                buttonType="icon"
                                aria-label="Options..."
                                anchor={TOP_LEFT_ANCHOR}
                                data-testid="header-option-menu"
                                disabled={isFormLocked}
                                buttonChildren={<MoreVertSVGIcon className="dropDownMenu moreIconBtn" />}
                            >
                                <MenuItem
                                    key='header-complete-button'
                                    className={!isDashboard && card?.statusId === CARDSTATUS.COMPLETED ? 'cardMenuItems selected' : 'cardMenuItems'}
                                    onClick={isFormLocked || (isDashboard && activeClient?.formStatus === "Client Submitted") ? () => { } :
                                        !isDashboard ? card?.statusId === CARDSTATUS.DOES_NOT_APPLY || (card?.formName === REDUX.INTERNAL_SETUP && card?.statusId === CARDSTATUS.COMPLETED) ? () => { } :
                                            () => setCompletedOnClick() :
                                            () => checkEntryInterviewForm()
                                    }
                                    data-testid="header-complete-option"
                                > {isDashboard ? STRING.SUBMIT_TEXT : 'Completed'}
                                </MenuItem>
                            </DropdownMenu>
                        </div>
                    </div>
                }
            </div>
            <SubmitDialog
                confirmText={STRING.FORM_SUBMIT_WARNING_TXT}
                onConfirmClick={onConfirmWarning}
                visible={visibleWarningDialog}
                onCancelWarning={hideWarningDialog}
            />
            <SubmitDialog
                confirmText={STRING.FORM_COMPLETE_WARNING_TXT}
                onConfirmClick={onConfirmCompletedWarning}
                visible={visibleCompletedWarningDialog}
                onCancelWarning={onCancelCompletedWarning}
            />
        </>

    );
}

export default HeaderImage;