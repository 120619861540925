import * as EVENT from '@utilities/constants/triggerKeys';
import * as REDUX from '@utilities/constants/redux';

const showFormOrgOth = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    
    if (event === 'Other') {
        name[0].groups[11].isVisible = true;
    } else {
        name[0].groups[11].isVisible = false;
    }
    
    setFormSections(name);
}

const triggeredEvent = (trigger, event, options) => {
    switch (trigger) {
    case 'showFormOrgOth': 
        showFormOrgOth(event, options);
        break;
    default:
        break;
    }
};

const showBasicDataltrDetermineAtt = (options) => {
    const { field, logic } = options;
    const basicDataForm = logic.triggerRelatedForms?.basicData;
    const entryExperienceForm = logic.triggerRelatedForms?.entryExperience;
    const isEntryirsNoteAnswered = entryExperienceForm[0].groups[1].fields[1].value;
    const ltrDetermine = field.default;
    if (basicDataForm && (!isEntryirsNoteAnswered || ltrDetermine === EVENT.KEY_YES)) {
        basicDataForm[3].groups[2].isVisible = false;
        logic.setGlobalFormState(REDUX.BASIC_DATA, basicDataForm);
    } else {
        basicDataForm[3].groups[2].isVisible = true;
        logic.setGlobalFormState(REDUX.BASIC_DATA, basicDataForm);
    }
};

const showBasicDataappExemptCopy = (options) => {
    const { field, logic } = options;
    const basicDataForm = logic.triggerRelatedForms?.basicData;

    if (basicDataForm) {
        basicDataForm[3].groups[3].isVisible = field.default === EVENT.KEY_YES;
        logic.setGlobalFormState(REDUX.BASIC_DATA, basicDataForm);
    }
};

const showBasicDatasubsIncl = (options) => {
    const { field, logic } = options;
    const basicDataForm = logic.triggerRelatedForms?.basicData;
    if (basicDataForm) {
        const subsIncl = basicDataForm[3].groups[7].fields[1].default || basicDataForm[3].groups[7].fields[1].value;
        basicDataForm[3].groups[7].isVisible = field.default;
        basicDataForm[3].groups[8].isVisible = field.default && subsIncl === EVENT.KEY_YES;
        basicDataForm[3].groups[9].isVisible = field.default && subsIncl === EVENT.KEY_NO;
        basicDataForm[3].groups[10].isVisible = field.default && subsIncl === EVENT.KEY_NO;

        logic.setGlobalFormState(REDUX.BASIC_DATA, basicDataForm);
    }
};

const showSupplementStatement = (options) => {
    const { field, logic, section } = options;
    const supFinStmntsForm = logic.triggerRelatedForms?.supplementalFinancialStatements;

    if (supFinStmntsForm) {
        supFinStmntsForm[4].isVisible = field.default === EVENT.KEY_NO && section.groups[12].fields[1].default === EVENT.KEY_AUDIT;
        if (supFinStmntsForm.length === 6) supFinStmntsForm[5].isVisible = supFinStmntsForm[4].isVisible;
        logic.setGlobalFormState(REDUX.SUPPLEMENTAL_FINANCIAL_STATEMENTS, supFinStmntsForm);
        if ( field.default === EVENT.KEY_NO && section.groups[12].fields[1].default === EVENT.KEY_AUDIT) {        
            field.requiredCardsToSet = [ REDUX.SUPPLEMENTAL_FINANCIAL_STATEMENTS ];
            field.isSetRequiredCards = true;
        } else {
            field.requiredCardsToSet = [ REDUX.SUPPLEMENTAL_FINANCIAL_STATEMENTS ];
            field.isSetRequiredCards = false;
        }
    }

}

const showEntryreqSchdChkDv = (options) => {
    const { field, logic, section } = options;
    const entryExperienceForm = logic.triggerRelatedForms?.entryExperience;
    const supFinStmntsForm = logic.triggerRelatedForms?.supplementalFinancialStatements;

    if (supFinStmntsForm) {
        supFinStmntsForm[4].isVisible = field.default === EVENT.KEY_AUDIT && (section.groups[13].fields[1].default === EVENT.KEY_NO
            //  && section.groups[13].isVisible === EVENT.KEY_YES
            );
        if (supFinStmntsForm.length === 6) supFinStmntsForm[5].isVisible = supFinStmntsForm[4].isVisible;
        logic.setGlobalFormState(REDUX.SUPPLEMENTAL_FINANCIAL_STATEMENTS, supFinStmntsForm);
    }

    if (entryExperienceForm) {
        section.groups[13].isVisible = field.default === EVENT.KEY_AUDIT;
        entryExperienceForm[1].groups[2].isVisible = field.default !== EVENT.KEY_AUDIT;
        logic.setGlobalFormState(REDUX.ENTRY_INTERVIEW, entryExperienceForm);

        const entryQuestion1 = entryExperienceForm[0].groups[4].fields[1];
        const entryQuestion2 = entryExperienceForm[0].groups[5].fields[1];
        const entryQuestion3 = entryExperienceForm[1].groups[0].fields[1];
        const entryQuestion4 = entryExperienceForm[1].groups[1].fields[1];
        const entryQuestion5 = entryExperienceForm[1].groups[2].fields[1];

        if ( entryQuestion1.value === EVENT.KEY_YES || entryQuestion2.value === EVENT.KEY_YES || entryQuestion3.value === EVENT.KEY_YES ||
            entryQuestion4.value === EVENT.KEY_YES || ((field.default !== EVENT.KEY_AUDIT && entryQuestion5.value === EVENT.KEY_YES) || (field.default === EVENT.KEY_AUDIT && (section.groups[13].fields[1].default === EVENT.KEY_NO && section.groups[13].isVisible === EVENT.KEY_YES))) ) {        
            field.requiredCardsToSet = [ REDUX.SUPPLEMENTAL_FINANCIAL_STATEMENTS ];
            field.isSetRequiredCards = true;
        } else if(field.default === EVENT.KEY_AUDIT && (section.groups[13].fields[1].default === EVENT.KEY_NO)){
            field.requiredCardsToSet = [ REDUX.SUPPLEMENTAL_FINANCIAL_STATEMENTS ];
            field.isSetRequiredCards = true;
        } else {
            field.requiredCardsToSet = [ REDUX.SUPPLEMENTAL_FINANCIAL_STATEMENTS ];
            field.isSetRequiredCards = false;
        }
    }
};

const showFinancialsSections = (options) => {
    const { field, logic } = options;
    const financialsForm = logic.triggerRelatedForms?.financials;
    const isNoneOrNoneOftheAbove = field.default === EVENT.KEY_NONE || field.default === EVENT.KEY_NONE_OF_THE_ABOVE;
    const isBlank = field.default.trim() === EVENT.KEY_EMPTY
    
    if (financialsForm) {
        financialsForm[1].isVisible = isNoneOrNoneOftheAbove || isBlank;
        logic.setGlobalFormState(REDUX.FINANCIALS, financialsForm);
    }
};

const setTaxCompothFilings1096Label = (options) => {
    const { field, logic } = options;
    const taxComplianceForm = logic.triggerRelatedForms?.taxCompliance;
    const hospitalForm = logic.triggerRelatedForms?.hospitalForm;

    const year = field.default || '';

    if (taxComplianceForm) {
        taxComplianceForm[0].groups[0].fields[0].default = `Enter the number from Box 3 of all Forms 1096 for the calendar year ending ${year}. Enter 0 if not applicable.`;
        taxComplianceForm[0].groups[0].fields[0].value = `Enter the number from Box 3 of all Forms 1096 for the calendar year ending ${year}. Enter 0 if not applicable.`;
        taxComplianceForm[0].groups[3].fields[0].default = `Enter the number of W-2's filed with the IRS for the calendar year ending ${year}. Enter 0 if not applicable.`;
        taxComplianceForm[0].groups[3].fields[0].value = `Enter the number of W-2's filed with the IRS for the calendar year ending ${year}. Enter 0 if not applicable.`;
        logic.setGlobalFormState(REDUX.TAX_COMPLIANCE_FILINGS, taxComplianceForm);
    }
    if(hospitalForm){
        const hospital501Entities = hospitalForm[15].groups[1].entities;
        hospitalForm[15].groups[1].lineItems.forEach((item, idx) => {
        const chnaYear = hospital501Entities[idx].sections[0].groups[3].fields[1].value;
          if(year?.trim() && chnaYear?.trim()){
            const lastCHNA = year - chnaYear;
            let hosChnaYrCHNAcalc = '-';
            if(lastCHNA >= 0 && lastCHNA < 100){
                hospital501Entities[idx].sections[0].groups[4].fields[1].default = lastCHNA.toString();
                hospital501Entities[idx].sections[0].groups[4].fields[1].value = lastCHNA.toString();
                hosChnaYrCHNAcalc = lastCHNA;
            }else{
                hospital501Entities[idx].sections[0].groups[4].fields[1].default = '';
                hospital501Entities[idx].sections[0].groups[4].fields[1].value = '';
            }
            if(lastCHNA <3 && lastCHNA >= 0){
                hospital501Entities[idx].sections[0].groups[5].fields[1].default = EVENT.KEY_YES;
                hospital501Entities[idx].sections[0].groups[5].fields[1].value = EVENT.KEY_YES;
                hospital501Entities[idx].sections[0].groups[6].isVisible = true;
                hospital501Entities[idx].sections[0].groups[7].isVisible = true;
                hospital501Entities[idx].sections[0].groups[8].isVisible = true;
                hospital501Entities[idx].sections[0].groups[9].isVisible = true;
                hospital501Entities[idx].sections[0].groups[10].isVisible = true;
                hospital501Entities[idx].sections[0].groups[11].isVisible = true;
                hospital501Entities[idx].sections[0].groups[12].isVisible = true;
                hospital501Entities[idx].sections[0].groups[13].isVisible = true;
                hospital501Entities[idx].sections[0].groups[14].isVisible = true;
                hospital501Entities[idx].sections[0].groups[15].isVisible = true;
                hospital501Entities[idx].sections[0].groups[16].isVisible = true;
            }else if(lastCHNA >= 0){
                hospital501Entities[idx].sections[0].groups[5].fields[1].default = EVENT.KEY_NO;
                hospital501Entities[idx].sections[0].groups[5].fields[1].value = EVENT.KEY_NO;
            }else{
                hospital501Entities[idx].sections[0].groups[5].fields[1].default = '';
                hospital501Entities[idx].sections[0].groups[5].fields[1].value = '';
            }
            item[1].default = `Most recently conducted a CHNA: ${chnaYear}\nNumber of years since last CHNA: ${hosChnaYrCHNAcalc}`;
            item[1].value = `Most recently conducted a CHNA: ${chnaYear}\nNumber of years since last CHNA: ${hosChnaYrCHNAcalc}`;
          }else{
            // hospital501Entities[idx].sections[0].groups[3].fields[1].default = '';
            hospital501Entities[idx].sections[0].groups[4].fields[1].default = '';
            hospital501Entities[idx].sections[0].groups[4].fields[1].value = '';
            hospital501Entities[idx].sections[0].groups[5].fields[1].default = '';
            hospital501Entities[idx].sections[0].groups[5].fields[1].value = '';
            hospital501Entities[idx].sections[0].groups[6].isVisible = false;
            hospital501Entities[idx].sections[0].groups[7].isVisible = false;
            hospital501Entities[idx].sections[0].groups[8].isVisible = false;
            hospital501Entities[idx].sections[0].groups[9].isVisible = false;
            hospital501Entities[idx].sections[0].groups[10].isVisible = false;
            hospital501Entities[idx].sections[0].groups[11].isVisible = false;
            hospital501Entities[idx].sections[0].groups[12].isVisible = false;
            hospital501Entities[idx].sections[0].groups[13].isVisible = false;
            hospital501Entities[idx].sections[0].groups[14].isVisible = false;
            hospital501Entities[idx].sections[0].groups[15].isVisible = false;
            hospital501Entities[idx].sections[0].groups[16].isVisible = false;
            // const chnaYear = hospital501Entities[idx].sections[0].groups[3].fields[1].default;
            if(chnaYear){
              item[1].default = `Most recently conducted a CHNA: ${chnaYear}\nNumber of years since last CHNA:`;
              item[1].value = `Most recently conducted a CHNA: ${chnaYear}\nNumber of years since last CHNA:`;
            }else{
              item[1].default = '';
              item[1].value = '';
            }
          }
        });
        logic.setGlobalFormState(REDUX.HOSPITAL, hospitalForm);
    }
};

const setTaxExBond8038sCcAttach = (options) => {
    const { field, logic } = options;
    const taxExemptBondsForm = logic.triggerRelatedForms?.taxExemptBondsForm;
    if (taxExemptBondsForm) {
        taxExemptBondsForm[0].groups[2].isVisible = field.default === EVENT.KEY_NO;
        taxExemptBondsForm[0].groups[3].isVisible = field.default === EVENT.KEY_NO;
        taxExemptBondsForm[0].groups[4].isVisible = field.default === EVENT.KEY_NO;
        logic.setGlobalFormState(REDUX.TAX_EXEMPT_BONDS, taxExemptBondsForm);
    }
};

const sectionFieldLogic = (logicFunction, options) => {
    switch (logicFunction) {
    case 'showBasicDatasubsIncl':
        showBasicDatasubsIncl(options);
        break;
    case 'showBasicDataltrDetermineAtt':
        showBasicDataltrDetermineAtt(options);
        break;
    case 'showBasicDataappExemptCopy':
        showBasicDataappExemptCopy(options);
        break;
    case 'showEntryreqSchdChkDv':
        showEntryreqSchdChkDv(options);
        break;
    case 'showFinancialsSections':
        showFinancialsSections(options);
        break;
    case 'showSupplementStatement':
        showSupplementStatement(options);
        break;
    case 'setTaxCompothFilings1096Label':
        setTaxCompothFilings1096Label(options);
        break;
    case 'setTaxExBond8038sCcAttach':
        setTaxExBond8038sCcAttach(options);
        break;
    default:
        break;
    }
};

export {
    triggeredEvent,
    sectionFieldLogic
};