import { useState, useEffect } from 'react';
import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRendererHelper from '@components/formRenderer';
import { triggeredEvent, sectionFieldLogic } from './financialsLogicTrigger';
import { pullFieldValue } from '@utilities/pullFieldValue';
import * as NAV from '@utilities/constants/navigation';
import NoActiveClient from '@views/dashboard/noActiveClientPage';
import useLoadOrganizer from '@utilities/hooks/useLoadOrganizer';


export const sections = [
	{
		title: 'Financial Statements & Reporting',
		isVisible: true,
		sectionId: 2,
		groups: [
			{
				groupId: 1,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: 'What is the accounting method used to prepare form 990?',
						name: 'fsRptAcctMthdPYLabel',
						type: 'label',
						col: 10
					},
					{
						label: '',
						name: 'fsRptAcctMthdPY',
						type: 'select',
						lookup: 'FSRPTACCTMTHDPY_OPTIONS',
						default: 'Cash',
						col: 2,
						logicFunction: [
							{ trigger: 'showFsRptAcctMthdOthPY', isDifferentGroup: true },
						],
						prior: true,
						isPriorEditable: true,
						axcess: {
							pull: {
								fields: ['XFDSGENO.14', 'XFDSGENO.15'],
								fn: 'fsRptAcctMthdPYPull'
							}
						}
					},
				]
			},
			{
				groupId: 2,
				isVisible: false,
				hasDivider: true,
				fields: [
					{
						label: 'Describe method',
						name: 'fsRptAcctMthdOthPYLabel',
						type: 'label',
						class: 'labelTitleTextArea',
						col: 12
					},
					{
						label: 'Please explain',
						placeholder: 'Please explain',
						name: 'fsRptAcctMthdOthPY',
						type: 'textarea',
                        maxLength: 19,
						rows: 1,
						class: 'fieldTextArea',
						col: 12,
						...pullFieldValue(['XFDSGENO.15'], false)
					},
				]
			},
			{
				groupId: 3,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: `Were the organization's financial statements compiled or reviewed by an independent accountant?`,
						name: 'fsRptAcctRevIndAcctLabel',
						type: 'label',
						col: 9
					},
					{
						label: '',
						name: 'fsRptAcctRevIndAcct',
						type: 'radio',
						lookup: 'YESNO_RADIO',
						col: 3,
						logicFunction: [
							{ trigger: 'showFsRptAcctRevBasis', isDifferentGroup: true },
						]
					},
				]
			},
			{
				groupId: 4,
				isVisible: false,
				hasDivider: true,
				fields: [
					{
						label: `On what basis were the organization's financial statements compiled or reviewed?`,
						name: 'fsRptAcctRevBasisLabel',
						type: 'label',
						col: 9
					},
					{
						label: '',
						name: 'fsRptAcctRevBasis',
						type: 'select',
						lookup: 'FSRPTACCTREVBASIS_OPTIONS',
						col: 3
					}, // TODO: Review radio button options
				]
			},
			{
				groupId: 5,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: `Were the organization's financial statements audited by an independent accountant?`,
						name: 'fsRptAcctAudIndAcctLabel',
						type: 'label',
						col: 9
					},
					{
						label: '',
						name: 'fsRptAcctAudIndAcct',
						type: 'radio',
						lookup: 'YESNO_RADIO',
						col: 3,
						logicFunction: [
							{ trigger: 'showFsRptAcctAudBasis', isDifferentGroup: true },
						]
					},
				]
			},
			{
				groupId: 6,
				isVisible: false,
				hasDivider: true,
				fields: [
					{
						label: `On what basis were the organization's financial statements audited?`,
						name: 'fsRptAcctAudBasisLabel',
						type: 'label',
						col: 9
					},
					{
						label: '',
						name: 'fsRptAcctAudBasis',
						type: 'select',
						lookup: 'FSRPTACCTREVBASIS_OPTIONS',
						col: 3
					}, // TODO: Review radio button options
				]
			},
			{
				groupId: 7,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: `
							Does the organization have a committee that assumes responsibility 
							for oversight of the audit, review or compilation of its financial 
							statements and selection of an independent accountant?`,
						name: 'fsRptAcctCtteOverLabel',
						type: 'label',
						col: 9
					},
					{
						label: '',
						name: 'fsRptAcctCtteOver',
						type: 'radio',
						lookup: 'YESNO_RADIO',
						col: 3,
						logicFunction: [
							{ trigger: 'showFsRptAcctChgOverProc', isDifferentGroup: true },
						]
					},
				]
			},
			{
				groupId: 8,
				isVisible: false,
				hasDivider: true,
				fields: [
					{
						label: `
							Has the organization changed either its oversight 
							process or selection process during the filing year?`,
						name: 'fsRptAcctChgOverProcLabel',
						type: 'label',
						col: 9
					},
					{
						label: '',
						name: 'fsRptAcctChgOverProc',
						type: 'radio',
						lookup: 'YESNO_RADIO',
						col: 3,
						logicFunction: [
							{ trigger: 'showFsRptAcctChgOverDesc', isDifferentGroup: true },
						]
					},
				]
			},
			{
				groupId: 9,
				isVisible: false,
				hasDivider: true,
				fields: [
					{
						label: 'Please describe the change:',
						name: 'fsRptAcctChgOverDescLabel',
						type: 'label',
						class: 'labelTitleTextArea',
						col: 12
					},
					{
						label: 'Please explain',
						placeholder: 'Please explain',
						name: 'fsRptAcctChgOverDesc',
						type: 'textarea',
						maxRows: 3,
						class: 'fieldTextArea',
						col: 12
					},
				]
			},
			{
				groupId: 10,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: `
							As a result of a federal award, was the organization required 
							to undergo an audit or audits as set forth in the Single 
							Audit Act and OMB Circular A-133?`,
						name: 'fsRptAcctFedReqAudLabel',
						type: 'label',
						col: 9
					},
					{
						label: '',
						name: 'fsRptAcctFedReqAud',
						type: 'radio',
						lookup: 'YESNO_RADIO',
						col: 3,
						logicFunction: [
							{ trigger: 'showFsRptAcctFedReqAudMiss', isDifferentGroup: true },
						]
					},
				]
			},
			{
				groupId: 11,
				isVisible: false,
				hasDivider: true,
				fields: [
					{
						label: 'Did the organization undergo the required audit(s)?',
						name: 'fsRptAcctFedReqAudMissLabel',
						type: 'label',
						col: 9
					},
					{
						label: '',
						name: 'fsRptAcctFedReqAudMiss',
						type: 'radio',
						lookup: 'YESNO_RADIO',
						col: 3,
						logicFunction: [
							{ trigger: 'showFsRptAcctFedReqAudMissExp', isDifferentGroup: true },
						]
					},
				]
			},
			{
				groupId: 12,
				isVisible: false,
				fields: [
					{
						label: 'Explain why not:',
						name: 'fsRptAcctFedReqAudMissExpLabel',
						type: 'label',
						class: 'labelTitleTextArea',
						col: 12
					},
					{
						label: 'Please explain',
						placeholder: 'Please explain',
						name: 'fsRptAcctFedReqAudMissExp',
						type: 'textarea',
						maxRows: 3,
						class: 'fieldTextArea',
						col: 12,
						prior: true,
						isPriorEditable: true,
						axcess: {
							pull: {
								fields: ['XFDS99O1.4', 'XFDS99O1.0'],
								fn: 'fsRptAcctFedReqAudMissExpPull',
								populateMultiple: true,
								separator: '\n',
							}
						}
					},
				]
			}
		]
	},
	{
		title: 'Financial Documents',
		isVisible: false,
		sectionId: 3,
		groups: [
			{
				groupId: 1,
				isVisible: true,
				hasDivider: true,
				groupType: 'lineItem',
				uploadType: 'drawer',
				class: 'householdEmploymentUpload',
				fields: [
					{ 
						label: `Please upload your organization's Trial Balance`, 
						name: 'uploadBtnLabel', 
						type: 'label',
						col: 9
					},
					{ type: 'lineButtons', col: 3 }  
				],
				lineItems: [],
				lineItemDetails: {
					headerLabels: [],
					footerLabels: [],
					lineButtons: [
						{ button: 'uploadOnly', uploadSectionName: 'uploadBtn', viewSectionId: 0 },
					]
				}
			},
		]
	},
	{  
    title: 'Additional Information',
    collapseClass: 'collapsibleForm',
    isVisible: true,
    isDefaultExpanded: false,
		isOptionalSectionMerge: true,
    sectionId: 4,
    groups: [
      {
        groupId: 2,
        groupType: 'details',
        fields: [
          { 
            label: 'Please use the area below to provide any additional information you think we may need or questions you would like to discuss.', 
            type: 'label', 
            class: 'labelLeftLight' 
          },
        ]
      },
      {
        groupId: 1,
        fields: [
          {
            label: 'Start typing to provide additional information', 
            name: 'additionalInfoTextArea', 
            type: 'textarea', 
            col: 12,
						maxLength: 9000
          }
        ]
      }
    ]
  },
];

export const getFinancialSectionTitles = () => {
	let titles = [];
	sections.forEach((section) => {
		if (!titles.includes(section.title)) {
			titles.push(section.title);
		}
	});
	return titles;
};

function FinancialSection(props) {
	const { isExportForm } = props;
	const { updateCard, REDUX } = usePageFramework(NAV.FINANCIALS);
	const [sectionState, setSectionState] = useState('');
	const {isActiveClient, isLoading} = useLoadOrganizer();

	useEffect(() => {
		if (!isExportForm) updateCard();

		setSectionState(sections);
		// eslint-disable-next-line	
	}, []);

	const axcessIdentifiers = [
		{ section: 'General', id: 'Basic Data' },
		{ section: 'Schedules', id: 'Sch O - Supplemental Information for Form 990' },
	];

	return (
		<>
			{!isLoading && isActiveClient && <FormRendererHelper
				sections={sectionState}
				key={REDUX.FINANCIALS}
				formName={REDUX.FINANCIALS}
				identifiers={axcessIdentifiers}
				triggeredEvent={triggeredEvent}
				sectionFieldLogic={sectionFieldLogic}
				isExportForm={isExportForm}
			/>}
			{!isLoading && !isActiveClient && <NoActiveClient />}
		</>
	);
}

export default FinancialSection;