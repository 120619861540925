// common modules
import { Button, Dialog } from 'react-md';

// custom modules
import '@components/dialog/customDialog/customDialog.css';
import usePageFramework from '@utilities/hooks/usePageFramework';

function CustomDialog(props) {
    const { REDUX, ACTION, dispatch, selectState } = usePageFramework();
    const title = selectState(REDUX.CUSTOM_DIALOG_TITLE);
    const message = selectState(REDUX.CUSTOM_DIALOG_MSG);
    const uploadProgress = selectState(REDUX.UPLOAD_PROGRESS_VISIBLE);
    const isCloseButtonHidden = selectState(REDUX.HIDE_CUSTOM_DIALOG_CLOSE_BUTTON) || false;

    return (
        <Dialog
            id="custom-dialog"
            defaultFocus="first"
            data-testid='custom-dialog'
            visible={props.visible}
            onRequestClose={() => { }}
            modal={true}
            aria-labelledby="dialog-title"
            className='WarningDialogContainer'
        >
            <div className='WarningDialogInnerContainer' >
                <div className="SuccessDialogTitle">{title}</div>
                <div className="WarningDialogTextContainer">
                    <div className="WarningDialogText1"> {message}</div>
                </div>
                {
                    !isCloseButtonHidden && <div className='successDialogButton'>
                        <Button className="formNotesSaveActive" id="dialog-close" data-testid='dialog-close' 
                            onClick={() => { 
                                dispatch(ACTION.setShowCustomDialog(false)); 
                                if (uploadProgress) dispatch(ACTION.setUploadProgressVisible(false)); 
                            }} 
                        > CLOSE
                        </Button>
                    </div>
                }
            </div>
        </Dialog>
    );
}

export default CustomDialog;