
import './headerMenu.css'
import usePageFramework from '@utilities/hooks/usePageFramework';
import * as IMG from '@utilities/constants/images';
import * as STRING from '@utilities/constants/strings';
// import SearchField from '../searchField/index';

function HeaderMenu() {
	const { history, dispatch, selectState, REDUX, NAV, location, ACTION, validateCurrentCard, organizerId } = usePageFramework();
	const isPractitioner = selectState(REDUX.IS_PRACTITIONER);
    const isAdmin = selectState(REDUX.IS_ADMIN);
    const isClient = selectState(REDUX.IS_CLIENT);

	const activeReturn = selectState(REDUX.ACTIVE_RETURN);
	const toolbarTitle = selectState(REDUX.TOOLBAR_TITLE);
	const isPracDash = toolbarTitle === STRING.EXCHANGE_MANAGER_990 || toolbarTitle === 'Document Manager' || toolbarTitle === 'PDF Export';
	const active = 'headerMenuTextSelected headerMenuCustomMargin';
	const inactive = 'headerMenuText headerMenuCustomMargin';

	const goBack = () => {
		validateCurrentCard();
		dispatch(ACTION.setCurrentCardKey(STRING.DASHBOARD_KEY));
		dispatch(ACTION.setDashboardFilterStatus(6));
		dispatch(ACTION.setDashboardSortRequirements('traditional'));
		history.push(`/${organizerId}${NAV.DASHBOARD}`);
	};

	const tempLoadData = () => {
		if (isPractitioner || isAdmin || isClient) {
			validateCurrentCard();
			dispatch(ACTION.setCurrentCardKey(null));
			history.push(NAV.PRACTITIONERS_DASHBOARD);
		}
	};

	// const handleDocManager = () => {
	//     validateCurrentCard();
	//     dispatch(ACTION.setCurrentCardKey(null));inactiveHeaderMenuText
	//     history.push(NAV.DOCUMENT_MANAGER);
	// };

	const buildActiveReturn = () => {
		return (
			<div className="activeReturnContainer">
				<div
					className={
						activeReturn ? !isPracDash ?
							'headerMenuTextSelected headerMenuCustomMargin' :
							'headerMenuText headerMenuCustomMargin' :
							'inactiveHeaderMenuText headerMenuCustomMargin'
					}
					id="dashboard"
					onClick={activeReturn ? () => goBack() : () => { }}
					style={{ marginRight: '55px' }}
				>
					Active Client
				</div>
				{activeReturn ?
					<div onClick={() => goBack()} className="navClientInfoContainer">
						<div className='activeReturnName'>{activeReturn.displayName}</div>
						<div className='activeReturnNumber'>{`CLIENT ID #${activeReturn.clientNumber}`}</div>
					</div>
					: <> </>}
			</div>
		);
	};

	return (
		<div className="navbar">
			<div className="navbarMenu">
				<img className="site-logo" alt="CLA Navy colored logo" src={IMG.CLA_NAVY_LOGO} />
				{
					(isPractitioner || isAdmin || isClient) && isPracDash ?
						buildActiveReturn() :
						<>
							<div
								className={
									location.pathname === '/dashboard' || (location.pathname === '/' && (!isPractitioner && !isAdmin && !isClient)) ?
										active : inactive
								}
								id="dashboard"
								onClick={() => goBack()}
							>
								{STRING.DASHBOARD}
							</div>
						</>

				}

				{isPractitioner || isAdmin || isClient ?
					<div
						className={
							location.pathname === '/exchangeManager' || (location.pathname === '/' && (isPractitioner || isAdmin || isClient)) ?
								active : inactive
						}
						onClick={() => tempLoadData()}
						id="exchangeManager"
					>
						{STRING.EXCHANGE_MANAGER_990}
					</div> : <></> 
				} 
				{/* {
					toolbarTitle !== '990 Exchange Manager' &&
					<a
						className={inactive}
						href={`${process.env.REACT_APP_DOC_EXCHANGE_URI}?instance=${instanceLibraryName}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						Document Exchange
					</a>
				} */}
			</div>
			<div className="navbarIconButton">
				{/* TODO: Hidden until functionality is implemented */}
				{/* <SearchField placeholder="Search" />
                <BadgeContainer>
                    <Button
                        id="custom-badged-button"
                        aria-describedby="custom-badged-button-badge"
                        buttonType="icon"
                    >
                        <TextIconSpacing icon={<ExpandMoreSVGIcon />} iconAfter>
                            <NotificationsNoneSVGIcon />
                        </TextIconSpacing>
                    </Button>
                    <Badge id="custom-badged-button-badge" className="navbarBellBadge">3</Badge>
                </BadgeContainer>
                <Button id="combined-button-2">
                    <TextIconSpacing icon={<ExpandMoreSVGIcon />} iconAfter>
                        <Avatar src="icons/claAvatar.svg" />
                    </TextIconSpacing>
                </Button> */}
			</div>
		</div>
	);
}

export default HeaderMenu;