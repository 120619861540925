import React, { useState, useEffect } from 'react';
import { Validators } from 'cla-formrenderer';
import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRendererHelper from '@components/formRenderer';
import {
	triggeredEvent,
	sectionFieldLogic
} from './supplementalFinancialStatementsLogicTrigger';
import { pullFieldValue } from '@utilities/pullFieldValue';
import * as NAV from '@utilities/constants/navigation';
import NoActiveClient from '@views/dashboard/noActiveClientPage';
import useLoadOrganizer from '@utilities/hooks/useLoadOrganizer';

let sections = [];

export const getSuppFinStatementsTitles = () => {
	let titles = [];
	sections.forEach((section) => {
		if (!titles.includes(section.title)) {
			titles.push(section.title);
		}
	});
	return titles;
};

export const getSuppFinStatementsSection = () => {
	return sections;
}

function SuppFinStatements(props) {
	const { isExportForm } = props;
	const { updateCard, REDUX } = usePageFramework(NAV.SUPPLEMENTAL_FINANCIAL_STATEMENTS);
	const [sectionState, setSectionState] = useState('');
	const {isActiveClient, isLoading} = useLoadOrganizer();


	useEffect(() => {
		if (!isExportForm) updateCard();
		sections = [
			{
				title: 'Donor Advised Funds',
				isVisible: false,
				sectionId: 1,
				groups: [
					{
						groupId: 1,
						isVisible: true,
						fields: [
							{
								label: `
										Generally a donor advised fund is a fund or account: \n\n 1) That is separately identified by reference to contributions of a donor or donors; \n\n 2) That is owned and controlled by a sponsoring organization; and \n\n 3) For which the donor or donor advisor has or reasonably expects to have advisory privileges in the distribution or investment of amounts held in the donor advised fund or account because of the donor's status as a donor.
								`,
								name: 'textOne',
								type: 'label',
								col: 12,
								styles: { whiteSpace: 'pre-line' }
							}, // TODO: This should be a list
						]
					},
					{
						groupId: 2,
						isVisible: true,
						fields: [
							{ label: 'Please provide information for the following:', name: 'textTwo', type: 'label', col: 12 },
						]
					},
					{
						groupType: 'lineItem',
						groupId: 3,
						axcessGroup: {
							pull: {
								fromSections: ['Donor Advised Funds and Other Similar'],
								fieldPrefixes: ['XFDS99D1']
							}
						},
						hasDivider: true,
						fields: [
							{
								label: 'Total number of Donor Advised Funds at end of year:',
								name: 'supFinDonorFundsLabel',
								type: 'label'
							},
							{
								label: '0',
								name: 'supFinDonorFundsCountPY',
								type: 'label',
								isTotal: true,
								isMoney: false
							},
							{
								label: '',
								placeholder: '0',
								name: 'supFinDonorFundsCount',
								type: 'total',
								isTotal: true,
								isMoney: false
							},
						],
						prePopulate: [
							{
								label: 'Total number of Donor Advised Funds at end of year:',
								prePopulateAugments: [
									{
										name: 'XFDS99D1.0',
										priorModifiers: { preGroupName: 'supFinDonorFundsCountPY' },
										currentModifiers: { preGroupName: 'supFinDonorFundsCount' }
									}
								]
							},
						],
						prePopulateControls: ['label', 'label', 'total'],
						prePopulateIndexes: [{ priorIndex: 1, currentIndex: 2 }],
						lineItems: [],
						entities: [],
						currentEntity: -1,
                        headerLabelsPrintStyles: {gridTemplateColumns: '9fr 1.5fr 1.5fr'},
						lineItemDetails:
						{
							headerLabels: [
								{
									label: '', col: 8,
									colTablet: 2,
									styles: { paddingLeft: '48px' }
								},
								{
									label: 'Prior Year',
									col: 2,
									colTablet: 2,
									styles: { paddingLeft: '48px' }
								},
								{
									label: 'Current Year',
									col: 2,
									colTablet: 2,
									styles: { paddingLeft: '40px' }
								},
							],
							footerLabels: [],
							lineButtons: []
						}
					},
					{
						groupType: 'lineItem',
						groupId: 4,
						isShowHeader: false,
						axcessGroup: {
							pull: {
								fromSections: ['Donor Advised Funds and Other Similar'],
								fieldPrefixes: ['XFDS99D1']
							}
						},
						fields: [
							{
								label: '',
								name: 'firstDonorTableLabel',
								type: 'label'
							},
							{
								label: '',
								name: 'firstDonorTablePY',
								type: 'label',
								prior: true,
								isMoney: true,
								isTotal: true
							},
							{
								label: '',
								name: 'firstDonorTableFunds',
								type: 'money',
								isMoney: true,
								isTotal: true
							},
						],
						prePopulate: [
							{
								label: 'Total contributions to Donor Advised Funds during year:',
								prePopulateAugments: [
									{
										name: 'XFDS99D1.2',
										priorModifiers: { preGroupName: 'supFinDonorContAggValPY' },
										currentModifiers: { preGroupName: 'supFinDonorContAggVal' }
									}
								]
							},
							{
								label: 'Total grants from Donor Advised Funds during year:',
								prePopulateAugments: [
									{
										name: 'XFDS99D1.4',
										priorModifiers: { preGroupName: 'supFinDonorGrntAggValPY' },
										currentModifiers: { preGroupName: 'supFinDonorGrntAggVal' }
									}
								]
							},
							{
								label: 'Total aggregate value of Donor Advised Funds at end of Year:',
								prePopulateAugments: [
									{
										name: 'XFDS99D1.6',
										priorModifiers: { preGroupName: 'supFinDonorFundsNumPY' },
										currentModifiers: { preGroupName: 'supFinDonorFundsNum' }
									}
								]
							},
						],
						prePopulateControls: ['label', 'label', 'money'],
						prePopulateIndexes: [{ priorIndex: 1, currentIndex: 2 }],
						lineItems: [],
						entities: [],
						currentEntity: -1,
						lineItemDetails:
						{
							headerLabels: [
								{ label: '', col: 8, colTablet: 2, styles: { paddingLeft: '48px' } },
								{ label: '', col: 2, colTablet: 2, styles: { paddingLeft: '48px' } },
								{ label: '', col: 2, colTablet: 2, styles: { paddingLeft: '40px' } },
							],
							footerLabels: [],
							lineButtons: []
						}
					},
					{
						groupType: 'lineItem',
						groupId: 5,
						hasDivider: true,
						axcessGroup: {
							pull: {
								fromSections: ['Donor Advised Funds and Other Similar'],
								fieldPrefixes: ['XFDS99D1']
							}
						},
						fields: [
							{
								label: 'Total number of Similar Funds and Accounts at end of year:',
								name: 'supFinSimilarFundsCountLabel',
								type: 'label'
							},
							{
								label: '0',
								name: 'supFinSimilarFundsCountPY',
								type: 'label',
								prior: true,
								isTotal: true,
								isMoney: false
							},
							{
								label: '',
								placeholder: '0',
								name: 'supFinSimilarFundsCount',
								type: 'total',
								isTotal: true,
								isMoney: false
							},
						],
						prePopulate: [
							{
								label: 'Total number of Similar Funds and Accounts at end of year:',
								prePopulateAugments: [
									{
										name: 'XFDS99D1.1',
										priorModifiers: { preGroupName: 'supFinSimilarFundsCountPY' },
										currentModifiers: { preGroupName: 'supFinSimilarFundsCount' }
									}
								]
							},
						],
						prePopulateControls: ['label', 'label', 'total'],
						prePopulateIndexes: [{ priorIndex: 1, currentIndex: 2 }],
						lineItems: [],
						entities: [],
						currentEntity: -1,
                        headerLabelsPrintStyles: {gridTemplateColumns: '9fr 1.5fr 1.5fr'},
						lineItemDetails:
						{
							headerLabels: [
								{
									label: '', col: 8,
									colTablet: 2,
									styles: { paddingLeft: '48px' }
								},
								{
									label: 'Prior Year',
									col: 2,
									colTablet: 2,
									styles: { paddingLeft: '48px' }
								},
								{
									label: 'Current Year',
									col: 2,
									colTablet: 2,
									styles: { paddingLeft: '40px' }
								},
							],
							footerLabels: [],
							lineButtons: []
						}
					},
					{
						groupType: 'lineItem',
						groupId: 6,
						isShowHeader: false,
						axcessGroup: {
							pull: {
								fromSections: ['Donor Advised Funds and Other Similar'],
								fieldPrefixes: ['XFDS99D1']
							}
						},
						fields: [
							{
								label: '',
								name: 'secondDonorTableLabel',
								type: 'label'
							},
							{
								label: '',
								name: 'secondDonorTablePY',
								type: 'label',
								isTotal: true,
								isMoney: true,
								prior: true
							},
							{
								label: '',
								name: 'secondDonorTableFunds',
								type: 'money',
								isTotal: true,
								isMoney: true
							},
						],
						prePopulate: [
							{
								label: 'Total contributions to Similar Funds and Accounts during year:',
								prePopulateAugments: [
									{
										name: 'XFDS99D1.3',
										priorModifiers: { preGroupName: 'supFinSimilarContAggValPY' },
										currentModifiers: { preGroupName: 'supFinSimilarContAggVal' }
									}
								]
							},
							{
								label: 'Total grants from Similar Funds and Accounts during year:',
								prePopulateAugments: [
									{
										name: 'XFDS99D1.5',
										priorModifiers: { preGroupName: 'supFinSimilarGrntAggValPY' },
										currentModifiers: { preGroupName: 'supFinSimilarGrntAggVal' }
									}
								]
							},
							{
								label: 'Total aggregate value of Similar Funds and Accounts at end of Year:',
								prePopulateAugments: [
									{
										name: 'XFDS99D1.7',
										priorModifiers: { preGroupName: 'supFinSimilarFundsNumPY' },
										currentModifiers: { preGroupName: 'supFinSimilarFundsNum' }
									}
								]
							},
						],
						prePopulateControls: ['label', 'label', 'money'],
						prePopulateIndexes: [{ priorIndex: 1, currentIndex: 2 }],
						lineItems: [],
						entities: [],
						currentEntity: -1,
						lineItemDetails:
						{
							headerLabels: [
								{ label: '', col: 8, colTablet: 2, styles: { paddingLeft: '48px' } },
								{ label: '', col: 2, colTablet: 2, styles: { paddingLeft: '48px' } },
								{ label: '', col: 2, colTablet: 2, styles: { paddingLeft: '40px' } },
							],
							footerLabels: [],
							lineButtons: []
						}
					},
					{
						groupId: 7,
						isVisible: true,
						hasDivider: true,
						fields: [
							{ label: `Did the organization inform all donors and donor advisors in writing that the assets held in donor advised funds are the organization's property, subject to the organization's exclusive legal control?`, name: 'supFinDonorAdvWriteLabel', type: 'label', col: 9 },
							{ label: '', name: 'supFinDonorAdvWrite', type: 'radio', lookup: 'YESNO_RADIO', col: 3 },
						]
					},
					{
						groupId: 8,
						isVisible: true,
						hasDivider: true,
						fields: [
							{ label: `Did the organization inform all grantees, donors and donor advisors in writing that grant funds may be used only for charitable purposes and not for the benefit of the donor or donor advisor?`, name: 'supFinSimilarAdvWriteLabel', type: 'label', col: 9 },
							{ label: '', name: 'supFinSimilarAdvWrite', type: 'radio', lookup: 'YESNO_RADIO', col: 3 },
						]
					}
				]
			},
			{
				title: 'Conservation Easements',
				isVisible: false,
				sectionId: 2,
				groups: [
					{
						groupId: 1,
						isVisible: true,
						fields: [
							{
								label: 'Note: A conservation easement is a restriction (granted in perpetuity) on the use of, or changes made to, real property that is granted in perpetuity exclusively for conservation purposes. Conservation purposes include preserving land areas for outdoor recreation by, or for the education of, the general public; protecting a relatively natural habitat of fish, wildlife, or plants, or a similar ecosystem; preserving open space, including farmland and forest land, where preservation will yield a significant public benefit and is either for the scenic enjoyment of the general public or pursuant to a clearly defined federal, state, or local government conservation policy; and preserving a historically important land area or certified historic structure.',
								name: 'textThree',
								type: 'label',
								col: 12
							},
						]
					},
					{
						groupId: 2,
						isVisible: true,
						fields: [
							{
								label: 'Conservation easements were held by the organization for the following purpose(s). (Check all that apply):',
								name: 'textFour',
								type: 'label',
								col: 12
							},
						]
					},
					{
						groupId: 3,
						isVisible: true,
						hasDivider: true,
						fields: [
							{
								label: 'Preservation of land for public use (e.g., recreation or education)',
								name: 'supFinConEasePublicLabel',
								type: 'label',
								col: 10,
								class: 'labelSingleCheckbox'
							},
							{
								label: 'Yes',
								name: 'supFinConEasePublic',
								type: 'checkbox',
								col: 2,
								class: 'singleCheckboxMargin',
								isFieldCheckbox: true,
								prior: true,
								isPriorEditable: true,
								axcess: {
									pull: {
										fields: ['XFDS99D2.0'],
										fn: 'checkboxValuePull'
									}
								}
							}
						]
					},
					{
						groupId: 4,
						isVisible: true,
						hasDivider: true,
						fields: [
							{
								label: 'Protection of natural habitat',
								name: 'supFinConEaseNatureLabel',
								type: 'label',
								col: 10,
								class: 'labelSingleCheckbox'
							},
							{
								label: 'Yes',
								name: 'supFinConEaseNature',
								type: 'checkbox',
								col: 2,
								class: 'singleCheckboxMargin',
								isFieldCheckbox: true,
								prior: true,
								isPriorEditable: true,
								axcess: {
									pull: {
										fields: ['XFDS99D2.1'],
										fn: 'checkboxValuePull'
									}
								}
							}
						]
					},
					{
						groupId: 5,
						isVisible: true,
						hasDivider: true,
						fields: [
							{
								label: 'Preservation of open space',
								name: 'supFinConEaseOpenLabel',
								type: 'label',
								col: 10,
								class: 'labelSingleCheckbox'
							},
							{
								label: 'Yes',
								name: 'supFinConEaseOpen',
								type: 'checkbox',
								col: 2,
								class: 'singleCheckboxMargin',
								isFieldCheckbox: true,
								prior: true,
								isPriorEditable: true,
								axcess: {
									pull: {
										fields: ['XFDS99D2.2'],
										fn: 'checkboxValuePull'
									}
								}
							}
						]
					},
					{
						groupId: 6,
						isVisible: true,
						hasDivider: true,
						fields: [
							{
								label: 'Preservation of a historically important land area',
								name: 'supFinConEaseHistLandLabel',
								type: 'label',
								col: 10,
								class: 'labelSingleCheckbox'
							},
							{
								label: 'Yes',
								name: 'supFinConEaseHistLand',
								type: 'checkbox',
								col: 2,
								class: 'singleCheckboxMargin',
								isFieldCheckbox: true,
								prior: true,
								isPriorEditable: true,
								axcess: {
									pull: {
										fields: ['XFDS99D2.3'],
										fn: 'checkboxValuePull'
									}
								}
							}
						]
					},
					{
						groupId: 7,
						isVisible: true,
						hasDivider: true,
						fields: [
							{
								label: 'Preservation of a certified historic structure',
								name: 'supFinConEaseHistStructLabel',
								type: 'label',
								col: 10,
								class: 'labelSingleCheckbox'
							},
							{
								label: 'Yes',
								name: 'supFinConEaseHistStruct',
								type: 'checkbox',
								col: 2,
								class: 'singleCheckboxMargin',
								isFieldCheckbox: true,
								logicFunction: [
									{ trigger: 'showSupFinConEaseHistStructNo', isDifferentGroup: true },
								],
								prior: true,
								isPriorEditable: true,
								axcess: {
									pull: {
										fields: ['XFDS99D2.4'],
										fn: 'checkboxValuePull'
									}
								}
							}
						]
					},
					{
						groupId: 8,
						isVisible: true,
						fields: [
							{
								label: 'Provide the following for conservation easements held by the organization at the end of the tax year:',
								name: 'textFive',
								type: 'label',
								col: 12
							},
						]
					},
					{
						groupId: 9,
						isVisible: true,
						hasDivider: true,
						fields: [
							{
								label: 'Total number of conservation easements:',
								name: 'supFinConEaseNoLabel',
								type: 'label',
								col: 10
							},
							{
								label: '',
								name: 'supFinConEaseNo',
								type: 'total',
								col: 2,
								isVisible: true,
							},
						]
					},
					{
						groupId: 10,
						isVisible: true,
						hasDivider: true,
						fields: [
							{
								label: 'Total acreage subject to conservation easements:',
								name: 'supFinConEaseAcreLabel',
								type: 'label',
								col: 10
							},
							{
								label: '',
								name: 'supFinConEaseAcre',
								type: 'total',
								col: 2,
								isVisible: true,
							},
						]
					},
					{
						groupId: 11,
						isVisible: false,
						hasDivider: true,
						fields: [
							{
								label: 'Number of conservation easements on a certified historic structure:',
								name: 'supFinConEaseHistStructNoLabel',
								type: 'label',
								col: 10
							},
							{
								label: '',
								name: 'supFinConEaseHistStructNo',
								type: 'total',
								col: 2,
								logicFunction: [
									{ trigger: 'showSupFinConEaseHistNonNatNo', isDifferentGroup: true },
								]
							},
						]
					},
					{
						groupId: 12,
						isVisible: false,
						hasDivider: true,
						fields: [
							{
								label: `
									Number of certified historic structures acquired after July 25, 2006, 
									and not on a historic structure listed in the National Register of Historic Places:
								`,
								name: 'supFinConEaseHistNonNatNoLabel',
								type: 'label',
								col: 10
							},
							{
								label: '',
								name: 'supFinConEaseHistNonNatNo',
								type: 'total',
								col: 2,
								isVisible: true,
								logicFunction: [
									{ trigger: 'showSupFinConEaseHistStructRules', isDifferentGroup: true },
								]
							},
						]
					},
					{
						groupId: 13,
						isVisible: false,
						fields: [
							{ label: 'Does each certified historic structure acquired after July 25, 2006, satisfy the following rules required to be considered exclusively for conservation purposes?', name: 'supFinConEaseHistStructRulesLabel', type: 'label', col: 9 },
							{ label: '', name: 'supFinConEaseHistStructRules', type: 'radio', lookup: 'YESNO_RADIO', col: 3 },
						]
					},
					{
						groupId: 14,
						isVisible: false,
						hasDivider: true,
						fields: [
							{
								label: `\u2022 Includes a restriction that preserves the entire exterior of the building, including the front, sides, rear, and height of the building, and to prohibit any change in the exterior of the building that is inconsistent with the historical character of such exterior; and \n\n \u2022 Requires the donor and donee to enter into a written agreement certifying, among other things, that the donee organization has the resources to manage and enforce the restriction and a commitment to do so.`,
								name: 'textSix',
								type: 'label',
								col: 12,
								styles: { whiteSpace: 'pre-line' }
							},
						]
					},
					{
						groupId: 15,
						isVisible: true,
						hasDivider: true,
						fields: [
							{
								label: `
									Total number of conservation easements modified, transferred, released, 
									or terminated by the organization during the taxable year:
								`,
								name: 'supFinConEaseModifyLabel',
								type: 'label',
								col: 10
							},
							{
								label: '',
								name: 'supFinConEaseModify',
								type: 'total',
								col: 2,
								isVisible: true,
								logicFunction: [
									{ trigger: 'showSupFinConEaseModifyExp', isDifferentGroup: true },
								]
							},
						]
					},
					{
						groupId: 16,
						isVisible: false,
						hasDivider: true,
						fields: [
							{
								label: 'Explain the changes to the easements:',
								name: 'supFinConEaseModifyExpLabel',
								type: 'label',
								class: 'labelTitleTextArea',
								col: 12
							},
							{
								label: '',
								placeholder: 'Please enter your comments/questions',
								name: 'supFinConEaseModifyExp',
								type: 'textarea',
								maxRows: 3,
								class: 'fieldTextArea',
								maxLength: 900,
								col: 12
							},
						]
					},
					{
						groupId: 17,
						isVisible: true,
						hasDivider: true,
						fields: [
							{
								label: `
									Number of states in which the organization held a 
									conservation easement during the tax year:
								`,
								name: 'supFinConEaseNoStateLabel',
								type: 'label',
								col: 10
							},
							{
								label: '',
								name: 'supFinConEaseNoState',
								type: 'total',
								col: 2,
								isVisible: true,
							},
						]
					},
					{
						groupId: 18,
						isVisible: true,
						hasDivider: true,
						fields: [
							{
								label: 'Does the organization have a written policy regarding the periodic monitoring, inspection, and enforcement of the conservation easements it holds?',
								name: 'supFinConEasePolicyLabel',
								type: 'label',
								col: 9
							},
							{
								label: '',
								name: 'supFinConEasePolicy',
								type: 'radio',
								lookup: 'YESNO_RADIO',
								col: 3,
								logicFunction: [
									{ trigger: 'showSupFinConEasePolicyDesc', isDifferentGroup: true },
								]
							},
						]
					},
					{
						groupId: 19,
						isVisible: false,
						fields: [
							{
								label: 'Please briefly summarize policy:',
								name: 'supFinConEasePolicyDescLabel',
								type: 'label',
								class: 'labelTitleTextArea',
								col: 12
							},
							{
								label: 'Please explain',
								placeholder: 'Please explain',
								name: 'supFinConEasePolicyDesc',
								type: 'textarea',
								maxRows: 3,
								class: 'fieldTextArea',
								maxLength: 900,
								col: 12,
								...pullFieldValue(['XFDS99D2.18'], true)
							},
						]
					},
					{
						groupId: 20,
						isVisible: true,
						hasDivider: true,
						fields: [
							{
								label: `
								Total number of hours devoted to monitoring, inspecting or enforcing easements by 
								staff, volunteer, or independent contractors during the tax year:
								`,
								name: 'supFinConEaseHoursLabel',
								type: 'label',
								col: 10
							},
							{
								label: '',
								name: 'supFinConEaseHours',
								type: 'total',
								col: 2,
								isVisible: true,
							},
						]
					},
					{
						groupId: 21,
						isVisible: true,
						hasDivider: true,
						fields: [
							{
								label: `
									Amount of expenses incurred in monitoring, inspecting, 
									or enforcing easements during the tax year:
								`,
								name: 'supFinConEaseExpensesLabel',
								type: 'label',
								col: 10
							},
							{
								label: '',
								name: 'supFinConEaseExpenses',
								type: 'money',
								col: 2,
								isVisible: true,
								isMoney: true,
							},
						]
					},
					{
						groupId: 22,
						isVisible: true,
						fields: [
							{
								label: 'Describe how the organization reports conservation easements in its revenue and expense statements and balance sheet:',
								name: 'supFinConEaseRevReptLabel',
								type: 'label',
								class: 'labelTitleTextArea',
								col: 12
							},
							{
								label: 'Please explain',
								placeholder: 'Please explain',
								name: 'supFinConEaseRevRept',
								type: 'textarea',
								maxRows: 3,
								class: 'fieldTextArea',
								maxLength: 900,
								col: 12,
								...pullFieldValue(['XFDS99D2.15'], true)
							},
						]
					},
				]
			},
			{
				title: 'Art & Historical Treasures',
				isVisible: false,
				sectionId: 3,
				groups: [
					{
						groupId: 1,
						isVisible: true,
						fields: [
							{ label: 'This section is specifically for art collections intended for public exhibition, education, or furthering public service, and does not include art that is primarily decorative in nature.', name: 'textSeven', type: 'label', col: 12 },
						]
					},
					{
						groupId: 2,
						isVisible: true,
						hasDivider: true,
						fields: [
							{
								label: 'Did the organization elect to report in its revenue statement and balance sheet works of art, historical treasures or other similar items held for public exhibition, education, or research in furtherance of public service?',
								name: 'supFinArtExhbRptElectRptLabel',
								type: 'label',
								col: 9
							},
							{
								label: '',
								name: 'supFinArtExhbRptElectRpt',
								type: 'radio',
								lookup: 'YESNO_RADIO',
								col: 3,
								logicFunction: [
									{ trigger: 'showSupFinArtExhbRptElectRptDependents', isDifferentGroup: true },
								],
								prior: true,
								isPriorEditable: true,
								priorCompareOptions: {
									radioShouldLookup: true
								},
								axcess: {
									pull: {
										fields: ['XFDS99D3.0', 'XFDS99D3.2'],
										fn: 'supFinArtExhbRptElectRptPull'
									}
								}
							},
						]
					},
					{
						groupId: 3,
						isVisible: false,
						fields: [
							{ label: 'Provide the following related to those items:', name: 'textEight', type: 'label', col: 12 },
						]
					},
					{
						groupId: 4,
						isVisible: false,
						hasDivider: true,
						fields: [
							{
								label: 'Financial statements footnote text that describes these items',
								name: 'supFinArtExhbRptElectRptNoLabel',
								type: 'label',
								class: 'labelTitleTextArea',
								col: 12
							},
							{
								label: 'Please explain',
								placeholder: 'Please explain',
								name: 'supFinArtExhbRptElectRptNo',
								type: 'textarea',
								maxRows: 3,
								class: 'fieldTextArea',
								maxLength: 900,
								col: 12,
								...pullFieldValue(['XFDS99D3.1'], true)
							},
						]
					},
					{
						groupId: 5,
						isVisible: false,
						hasDivider: true,
						fields: [
							{
								label: 'Revenues included in the revenue statement',
								name: 'supFinArtExhbRptElectRptRevLabel',
								type: 'label',
								col: 10
							},
							{
								label: '',
								name: 'supFinArtExhbRptElectRptRev',
								type: 'money',
								col: 2,
								isVisible: true,
								isMoney: true,
							},
						]
					},
					{
						groupId: 6,
						isVisible: false,
						hasDivider: true,
						fields: [
							{
								label: 'Assets included in the balance sheet',
								name: 'supFinArtExhbRptElectRptAsstLabel',
								type: 'label',
								col: 10
							},
							{
								label: '',
								name: 'supFinArtExhbRptElectRptAsst',
								type: 'money',
								col: 2,
								isVisible: true,
								isMoney: true,
							},
						]
					},
					{
						groupId: 7,
						isVisible: true,
						hasDivider: true,
						fields: [
							{
								label: 'Did the organization hold works of art, historical treasures, or other similar assets for financial gain?',
								name: 'supFinArtGainHoldLabel',
								type: 'label',
								col: 9
							},
							{
								label: '',
								name: 'supFinArtGainHold',
								type: 'radio',
								lookup: 'YESNO_RADIO',
								col: 3,
								logicFunction: [
									{ trigger: 'showSupFinArtGainHoldDependents', isDifferentGroup: true },
								]
							},
						]
					},
					{
						groupId: 8,
						isVisible: false,
						fields: [
							{ label: 'Provide the following related to those items:', name: 'textNine', type: 'label', col: 12 },
						]
					},
					{
						groupId: 9,
						isVisible: false,
						hasDivider: true,
						fields: [
							{
								label: 'Revenues included in the revenue statement',
								name: 'supFinArtGainRptRevLabel',
								type: 'label',
								col: 10
							},
							{
								label: '',
								name: 'supFinArtGainRptRev',
								type: 'money',
								col: 2,
								isVisible: true,
								isMoney: true,
							},
						]
					},
					{
						groupId: 10,
						isVisible: false,
						hasDivider: true,
						fields: [
							{
								label: 'Assets included in the balance sheet',
								name: 'supFinArtGainRptAsstLabel',
								type: 'label',
								col: 10
							},
							{
								label: '',
								name: 'supFinArtGainRptAsst',
								type: 'money',
								col: 2,
								isVisible: true,
								isMoney: true,
							},
						]
					},
					{
						groupId: 11,
						isVisible: true,
						fields: [
							{ label: 'According to the organization\'s acquisition, accession or other records, which of the following is a significant use of the organization\'s collection items (check all that apply):', name: 'textTen', type: 'label', col: 12 },
						]
					},
					{
						groupId: 12,
						isVisible: true,
						hasDivider: true,
						fields: [
							{
								label: 'Public exhibition',
								name: 'supFinArtUsePublicLabel',
								type: 'label',
								col: 10,
								class: 'labelSingleCheckbox'
							},
							{
								label: 'Yes',
								name: 'supFinArtUsePublic',
								type: 'checkbox',
								col: 2,
								class: 'singleCheckboxMargin',
								isFieldCheckbox: true,
								prior: true,
								isPriorEditable: true,
								axcess: {
									pull: {
										fields: ['XFDS99D3.8'],
										fn: 'checkboxValuePull'
									}
								}
							}
						]
					},
					{
						groupId: 13,
						isVisible: true,
						hasDivider: true,
						fields: [
							{
								label: 'Scholarly research',
								name: 'supFinArtUseResearchLabel',
								type: 'label',
								col: 10,
								class: 'labelSingleCheckbox'
							},
							{
								label: 'Yes',
								name: 'supFinArtUseResearch',
								type: 'checkbox',
								col: 2,
								class: 'singleCheckboxMargin',
								isFieldCheckbox: true,
								prior: true,
								isPriorEditable: true,
								axcess: {
									pull: {
										fields: ['XFDS99D3.9'],
										fn: 'checkboxValuePull'
									}
								}
							}
						]
					},
					{
						groupId: 14,
						isVisible: true,
						hasDivider: true,
						fields: [
							{
								label: 'Preservation for future generations',
								name: 'supFinArtUsePreserveLabel',
								type: 'label',
								col: 10,
								class: 'labelSingleCheckbox'
							},
							{
								label: 'Yes',
								name: 'supFinArtUsePreserve',
								type: 'checkbox',
								col: 2,
								class: 'singleCheckboxMargin',
								isFieldCheckbox: true,
								prior: true,
								isPriorEditable: true,
								axcess: {
									pull: {
										fields: ['XFDS99D3.10'],
										fn: 'checkboxValuePull'
									}
								}
							}
						]
					},
					{
						groupId: 15,
						isVisible: true,
						hasDivider: true,
						fields: [
							{
								label: 'Loan or exchange programs',
								name: 'supFinArtUseLoanLabel',
								type: 'label',
								col: 10,
								class: 'labelSingleCheckbox'
							},
							{
								label: 'Yes',
								name: 'supFinArtUseLoan',
								type: 'checkbox',
								col: 2,
								class: 'singleCheckboxMargin',
								isFieldCheckbox: true,
								prior: true,
								isPriorEditable: true,
								axcess: {
									pull: {
										fields: ['XFDS99D3.11'],
										fn: 'checkboxValuePull'
									}
								}
							}
						]
					},
					{
						groupId: 16,
						isVisible: true,
						hasDivider: true,
						fields: [
							{
								label: 'Other',
								name: 'supFinArtUseOtherLabel',
								type: 'label',
								col: 10,
								class: 'labelSingleCheckbox'
							},
							{
								label: 'Yes',
								name: 'supFinArtUseOther',
								type: 'checkbox',
								col: 2,
								class: 'singleCheckboxMargin',
								isFieldCheckbox: true,
								logicFunction: [
									{ trigger: 'showSupFinArtUseOtherDesc', isDifferentGroup: true },
								]
							}
						]
					},
					{
						groupId: 17,
						isVisible: false,
						hasDivider: true,
						fields: [
							{
								label: 'Describe the other significant use of the organization\'s collection items:',
								name: 'supFinArtUseOtherDescLabel',
								type: 'label',
								class: 'labelTitleTextArea',
								col: 12
							},
							{
								label: '',
								placeholder: 'Please enter your comments/questions',
								name: 'supFinArtUseOtherDesc',
								type: 'textarea',
								maxRows: 1,
								class: 'fieldTextArea',
								maxLength: 30,
								col: 12,
								...pullFieldValue(['XFDS99D3.12'], true)
							},
						]
					},
					{
						groupId: 18,
						isVisible: true,
						hasDivider: true,
						fields: [
							{
								label: `During the year, did the organization solicit or receive donations of art, historical treasures, or similar assets to be sold to raise funds rather than to be maintained as part of the organization's collection?`,
								name: 'supFinArtCollectRecToSellLabel',
								type: 'label',
								col: 9
							},
							{
								label: '',
								name: 'supFinArtCollectRecToSell',
								type: 'radio',
								lookup: 'YESNO_RADIO',
								col: 3
							},
						]
					},
					{
						groupId: 19,
						isVisible: true,
						fields: [
							{
								label: 'Provide a description of the organization\'s collections and explain how they further the organization\'s exempt purpose:',
								name: 'supFinArtCollectDescLabel',
								type: 'label',
								class: 'labelTitleTextArea',
								col: 12
							},
							{
								label: 'Please explain',
								placeholder: 'Please explain',
								name: 'supFinArtCollectDesc',
								type: 'textarea',
								maxRows: 3,
								class: 'fieldTextArea',
								maxLength: 900,
								col: 12,
								...pullFieldValue(['XFDS99D3.14'], true)
							},
						]
					},
				]
			},
			{
				title: 'Escrow & Custodial Arrangements',
				isVisible: false,
				sectionId: 4,
				groups: [
					{
						groupId: 1,
						isVisible: true,
						hasDivider: true,
						fields: [
							{ label: 'Is the organization an agent, trustee, custodian or other intermediary for contributions or other assets not included in the balance sheet?', name: 'supFinCustNotBSLabel', type: 'label', col: 9 },
							{
								label: '',
								name: 'supFinCustNotBS',
								type: 'radio',
								lookup: 'YESNO_RADIO',
								col: 3,
								logicFunction: [{ trigger: 'showSupFinCustNotBalDetails' }],
								prior: true,
								isPriorEditable: true,
								priorCompareOptions: {
									radioShouldLookup: true
								},
								axcess: {
									pull: {
										fields: ['XFDS99D4.0'],
										fn: 'YNToRadioPull'
									}
								}
							},
						]
					},
					{
						groupId: 2,
						isVisible: false,
						hasDivider: true,
						fields: [
							{
								label: 'Please describe the arrangement:',
								name: 'supFinCustNotBSDescLabel',
								type: 'label',
								col: 9
							},
							{
								label: 'Please explain',
								name: 'supFinCustNotBSDesc',
								type: 'textarea',
								col: 12,
								maxRows: 3,
								class: 'fieldTextArea',
								...pullFieldValue(['XFDS99D4.1'], true)
							},
						]
					},
					{
						groupId: 3,
						isVisible: false,
						fields: [
							{ label: 'Provide the following information about unreported accounts:', name: 'textEleven', type: 'label', col: 12 },
						]
					},
					{
						groupId: 4,
						isVisible: false,
						hasDivider: true,
						fields: [
							{
								label: 'Beginning balance',
								name: 'supFinCustNotBalBeginLabel',
								type: 'label',
								col: 10
							},
							{
								label: '',
								name: 'supFinCustNotBalBegin',
								type: 'money',
								col: 2,
								...pullFieldValue(['XFDS99D4.5'], true)
							},
						]
					},
					{
						groupId: 5,
						isVisible: false,
						hasDivider: true,
						fields: [
							{ label: 'Additions during the year', name: 'supFinCustNotBalAddLabel', type: 'label', col: 10 },
							{ label: '', name: 'supFinCustNotBalAdd', type: 'money', col: 2 },
						]
					},
					{
						groupId: 6,
						isVisible: false,
						hasDivider: true,
						fields: [
							{ label: 'Distributions during the year', name: 'supFinCustNotBalDistLabel', type: 'label', col: 10 },
							{ label: '', name: 'supFinCustNotBalDist', type: 'money', col: 2 },
						]
					},
					{
						groupId: 7,
						isVisible: false,
						hasDivider: true,
						fields: [
							{ label: 'Ending Balance', name: 'supFinCustNotBalEndLabel', type: 'label', col: 10 },
							{ label: '', name: 'supFinCustNotBalEnd', type: 'money', col: 2 },
						]
					},
					{
						groupId: 8,
						isVisible: true,
						hasDivider: true,
						fields: [
							{
								label: 'Did the organization report any escrow account liabilities on the balance sheet that relate to the unreported assets?',
								name: 'supFinCustNotBalLiabLabel',
								type: 'label',
								col: 9
							},
							{
								label: '',
								name: 'supFinCustNotBalLiab',
								type: 'radio',
								lookup: 'YESNO_RADIO',
								col: 3,
								logicFunction: [{ trigger: 'showSupFinCustNotBalLiabExp' }],
								prior: true,
								isPriorEditable: true,
								priorCompareOptions: {
									radioShouldLookup: true
								},
								axcess: {
									pull: {
										fields: ['XFDS99D4.6'],
										fn: 'YNToRadioPull'
									}
								}
							},
						]
					},
					{
						groupId: 9,
						isVisible: false,
						hasDivider: true,
						fields: [
							{
								label: 'Please describe the arrangement:',
								name: 'supFinCustNotBalLiabExpLabel',
								type: 'label',
								col: 9
							},
							{
								label: 'Please explain',
								name: 'supFinCustNotBalLiabExp',
								type: 'textarea',
								col: 12,
								maxRows: 3,
								class: 'fieldTextArea',
								...pullFieldValue(['XFDS99D4.7'], true)
							},
						]
					},
				]
			},
			{
				title: 'Endowment Funds',
				isVisible: false,
				sectionId: 5,
				groups: [
					{
						groupId: 1,
						isVisible: true,
						fields: [
							{
								htmlLabel: <React.Fragment key={'endowments-list'}>
									<ul key={'endowments-lists'} className='discList'>
										<li key={'endowments-list-1'} className='discListItem endowmentItem'><span key={'i1'}>Board Designated or Quasi-Endowments</span> are funds functioning as an endowment but are established by the organization itself, either from unrestricted donor gifts or organization funds. The organization itself imposes restrictions on the use of the funds and the restriction can be temporary or permanent. The governing board has the right to decide at any time to expend the funds.</li>
										<li key={'endowments-list-2'} className='discListItem endowmentItem'><span key={'i2'}>Permanent Endowments</span> are endowment funds that are established by donor-restricted gifts and are maintained to provide a permanent source of income, with the stipulation that principal must be invested and kept intact in perpetuity, while only the income generated can be used by the organization.</li>
										<li key={'endowments-list-3'} className='discListItem endowmentItem'><span key={'i3'}>Term Endowments</span> are endowment funds that are established by donor-restricted gifts and are maintained to provide a source of income for either a specified period of time or until a specified event occurs. These are temporarily restricted endowments.</li>
									</ul>
								</React.Fragment>,
								name: 'supFinSimilarFundsCount',
								type: 'label',
								col: 12,
								styles: { whiteSpace: 'pre-line' }
							}, // TODO: This should be a list
						]
					},
					// {
					// 	groupId: 1,
					// 	isVisible: true,
					// 	hasDivider: true,
					// 	fields: [
					// 		{ label: 'Note:', name: 'textTwelve', type: 'list', lookup: '', col: 12 },// TODO: Create list component
					// 	]
					// },
					{
						groupId: 2,
						isVisible: true,
						fields: [
							{ label: 'For all Term, Permanent and Quasi endowments provide the total:', name: 'textThirteen', type: 'label', col: 12 },
						]
					},
					{
						groupId: 3,
						axcessGroup: {
							pull: {
								postPopulateFn: 'setFieldValueIfNotFound',
								postPopulateMetadata: {
									pyDefaultValues: {
										supFinEndowBalBeg: '0',
									}
								},
							}
						},
						isVisible: true,
						hasDivider: true,
						fields: [
							{
								label: 'Beginning of year balance',
								name: 'supFinEndowBalBegLabel',
								type: 'label',
								col: 10
							},
					{
						label: '',
						name: 'supFinEndowBalBeg',
						type: 'money',
						maxLength: 15,
						logicFunction:[{trigger : 'showSupFinEndowBalEnd'}],
						col: 2,
						prior: true,
						isPriorEditable: false,
						axcess: {
							pull: {
								fields: ['XFDS99D5.6'],
								fn: 'anyValidPull',
							}
						},
					},
				]
			},
			{
				groupId: 4,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Contributions', name: 'supFinEndowContribLabel', type: 'label', col: 10 },
					{ label: '', name: 'supFinEndowContrib', type: 'money', maxLength: 15, col: 2, logicFunction:[{trigger : 'showSupFinEndowBalEnd'}], },
				]
			},
			{
				groupId: 5,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Investment earnings or losses', name: 'supFinEndowNetEarnLabel', type: 'label', col: 10 },
					{ label: '', name: 'supFinEndowNetEarn', type: 'money', maxLength: 15, col: 2, allowNegative: true, logicFunction:[{trigger : 'showSupFinEndowBalEnd'}], },
				]
			},
			{
				groupId: 6,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Grants or scholarships', name: 'supFinEndowGrantLabel', type: 'label', col: 10 },
					{ label: '', name: 'supFinEndowGrant', type: 'money', maxLength: 15, col: 2, logicFunction:[{trigger : 'showSupFinEndowBalEnd'}], },
				]
			},
			{
				groupId: 7,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Other expenditures for facilities and programs', name: 'supFinEndowOtherExpLabel', type: 'label', col: 10 },
					{ label: '', name: 'supFinEndowOtherExp', type: 'money', maxLength: 15, col: 2, logicFunction:[{trigger : 'showSupFinEndowBalEnd'}], },
				]
			},
			{
				groupId: 8,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Administrative expenses', name: 'supFinEndowAdmnExpLabel', type: 'label', col: 10 },
					{ label: '', name: 'supFinEndowAdmnExp', type: 'money', maxLength: 15, col: 2, logicFunction:[{trigger : 'showSupFinEndowBalEnd'}], },
				]
			},
			{
				groupId: 9,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'End of year balances', name: 'supFinEndowBalEndLabel', type: 'label', col: 10 },
					{ label: '', name: 'supFinEndowBalEnd', type: 'readOnly', isReadOnlyAmount: true, col: 2, default: '$0' },
				]
			},
			{
				groupId: 10,
				isVisible: true,
				fields: [
					{ label: 'Provide the estimated percentage of the year-end balances held as:', name: 'textFourteen', type: 'label', col: 12 },
				]
			},
			{
				groupId: 11,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Board Designated or Quasi-Endowment', name: 'supFinEndowPcntQuasiLabel', type: 'label', col: 10 },
					{ label: '', name: 'supFinEndowPcntQuasi', type: 'percent', col: 2, validations: { ...Validators.maxValue(100), } },
				]
			},
			{
				groupId: 12,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Permanent Endowments', name: 'supFinEndowPcntPermLabel', type: 'label', col: 10 },
					{ label: '', name: 'supFinEndowPcntPerm', type: 'percent', col: 2, validations: { ...Validators.maxValue(100), } },
				]
			},
			{
				groupId: 13,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Term Endowments', name: 'supFinEndowPcntTermLabel', type: 'label', col: 10 },
					{ label: '', name: 'supFinEndowPcntTerm', type: 'percent', col: 2, validations: { ...Validators.maxValue(100), }, },
				]
			},
			{
				groupId: 14,
				isVisible: true,
				hasDivider: true,
				fields: [
							{
								label: 'Describe the intended uses of the organization\'s endowment funds:',
								name: 'supFinEndowUsesLabel',
								type: 'label',
								col: 9
							},
							{
								label: 'Please explain',
								name: 'supFinEndowUses',
								type: 'textarea',
								col: 12,
								maxRows: 3,
								class: 'fieldTextArea',
								...pullFieldValue(['XFDS99D5.13'], true)
							},
						]
					},
					{
						groupId: 15,
						isVisible: true,
						fields: [
							{ label: 'Are there endowment funds that are not in the possession of the organization but that are held and administered for the organization by:', name: 'textFifteen', type: 'label', col: 12 },
						]
					},
					{
						groupId: 16,
						isVisible: true,
						hasDivider: true,
						fields: [
							{
								label: 'Unrelated organizations?',
								name: 'supFinEndowNonPossUnrelLabel',
								type: 'label',
								col: 9
							},
							{
								label: '',
								name: 'supFinEndowNonPossUnrel',
								type: 'radio',
								lookup: 'YESNO_RADIO',
								col: 3,
								prior: true,
								isPriorEditable: true,
								priorCompareOptions: {
									radioShouldLookup: true
								},
								axcess: {
									pull: {
										fields: ['XFDS99D5.10'],
										fn: 'checkboxToRadioPull'
									}
								}
							},
						]
					},
					{
						groupId: 17,
						isVisible: true,
						hasDivider: true,
						fields: [
							{
								label: 'Related organizations?',
								name: 'supFinEndowNonPossRelLabel',
								type: 'label',
								col: 9
							},
							{
								label: '',
								name: 'supFinEndowNonPossRel',
								type: 'radio',
								lookup: 'YESNO_RADIO',
								col: 3,
								logicFunction: [{ trigger: 'showSupFinEndowNonPossRelNames' }],
								prior: true,
								isPriorEditable: true,
								priorCompareOptions: {
									radioShouldLookup: true
								},
								axcess: {
									pull: {
										fields: ['XFDS99D5.11'],
										fn: 'checkboxToRadioPull'
									}
								}
							},
						]
					},
					{
						groupId: 18,
						isVisible: false,
						hasDivider: true,
						fields: [
							{ label: 'Provide the names of the related organizations that held funds:', name: 'supFinEndowNonPossRelNamesLabel', type: 'label', col: 9 },
							{ label: 'Please explain', name: 'supFinEndowNonPossRelNames', type: 'textarea', col: 12, maxRows: 3, class: 'fieldTextArea' },
						]
					},
				]
			},
			{  
				title: 'Additional Information',
				collapseClass: 'collapsibleForm',
				isVisible: false,
				isDefaultExpanded: false,
				isOptionalSectionMerge: true,
				sectionId: 6,
                triggered: 'showSupFinAddInfo',
				groups: [
					{
						groupId: 2,
						groupType: 'details',
						fields: [
							{ 
								label: 'Please use the area below to provide any additional information you think we may need or questions you would like to discuss.', 
								type: 'label', 
								class: 'labelLeftLight' 
							},
						]
					},
					{
						groupId: 1,
						fields: [
							{
								label: 'Start typing to provide additional information', 
								name: 'additionalInfoTextArea', 
								type: 'textarea', 
								col: 12,
								maxLength: 9000
							}
						]
					}
				]
			},
		];
		setSectionState(sections);
		// eslint-disable-next-line	
	}, []);

	const axcessIdentifiers = [
		{ section: 'Schedules', id: 'Sch D' }
	];

	return (
		<>
			{!isLoading && isActiveClient && <FormRendererHelper
				sections={sectionState}
				key={REDUX.SUPPLEMENTAL_FINANCIAL_STATEMENTS}
				formName={REDUX.SUPPLEMENTAL_FINANCIAL_STATEMENTS}
				triggeredEvent={triggeredEvent}
				sectionFieldLogic={sectionFieldLogic}
				identifiers={axcessIdentifiers}
				isExportForm={isExportForm}
			/>}
			{!isLoading && !isActiveClient && <NoActiveClient />}
		</>
	);
}

export default SuppFinStatements;