// common modules
import React from 'react';
import { useToggle } from "@react-md/utils";
import { Button } from "@react-md/button";
import { useState } from "react";
import {
	Dialog,
	DialogHeader,
	DialogTitle,
	DialogContent,
	DialogFooter,
} from "@react-md/dialog";

// custom modules
import './ManageOrganizers.css';
import RestfulTable from '@components/restfulTable/RestfulTable.js';
import * as NAV from '@utilities/constants/navigation';
import * as STRING from '@utilities/constants/strings';
import Breadcrumb from '@components/Breadcrumb.js';
import api from '@utilities/claApi';
import CreateOrganizerDialog from '@components/adminDashboard/createOrganizerDialog/index.js';
import EditOrganizerDialog from '@components/adminDashboard/editOrganizerDialog/index.js';

import { parseToISODate, parseToUSDate } from '@components/formRenderer/fields/utils/formatDate.js'

function ManageOrganizers(props) {
	const [showDialog, enableDialog, disableDialog] = useToggle(false);
	const [showCreateDialog, enableCreateDialog, disableCreateDialog] = useToggle(false);
	const [showEditDialog, enableEditDialog, disableEditDialog] = useToggle(false);
	const [targetOrganizer, setTargetOrganizer] = useState(null);
	// eslint-disable-next-line
	const [requestTableReload, enableTableReload, dsiableTableReload, toggleTableReload] = useToggle(false);
	const [dialog, setDialog] = useState({
		title: '',
		message: '',
	});
	const [yearEndDate, setYearEndDate] = useState('')

	const resourceName = 'Organizer';
	const resourceUri = 'organizers';
	const columns = [
		{ key: 'status', text: 'Status', create: { show: true, required: true }, edit: { show: true } },
		{ key: 'client.number', text: 'Client Number', create: { show: false }, edit: { show: false } },
		{ key: 'client.name', text: 'Client', create: { show: false }, edit: { show: false } },
		{ key: 'year', text: 'Year', create: { show: true, required: true }, edit: { show: false } },
		{ key: 'locked', text: 'Locked', type: 'boolean', create: { show: false, required: true }, edit: { show: true } },
	];
	const defaultSortKey = 'client.name';
	const defaultSortOrder = 'ascending';

	const navItems = [
		{ to: NAV.ADMIN_DASHBOARD, label: 'Admin Dashboard' },
		{ to: NAV.MANAGE_ORGANIZERS, label: 'Organizers', current: true }
	];

	const actions = [
		{
			label: 'Reset Form Data',
			onClick: (organizer) => {
				setDialog({
					title: 'Reset Form Data',
					message: 'Are you sure you want to reset form data?',
					allowConfirm: true,
					allowClose: true,
					onConfirm: () => {
						setDialog({
							title: 'Reset Form Data',
							message: 'Resetting form data...',
							allowConfirm: false,
							allowClose: false
						});

						// get the organizer details
						api.get(`/organizers/${organizer.id}`)
							.then((response) => {
								return response.data.forms;
							})
							.then((forms) => {
								const formPromises = forms.map((form) => {
									return api.delete(`forms/${form.id}`);
								});

								const json = {
									dashboard: null,
									status: STRING.ORGANIZER_NEW_STATUS_TEXT,
									lastUserActivityOn: new Date(),
								};

								formPromises.push(api.put(`/organizers/${organizer.id}`, json));

								return Promise.all(formPromises);
							})
							.then(() => {
								return api.get(`/organizers/${organizer.id}/documents`);
							})
							.then((response) => {
								const documents = response.data.results;

								const documentPromises = documents.map((document) => {
									return api.delete(`/organizers/${organizer.id}/documents/${document.id}`);
								});

								return Promise.all(documentPromises);
							})
							.then(() => {
								return api.get(`/organizers/${organizer.id}/notes`);
							}).then((response) => {
								const notes = response.data.results;

								const notesPromises = notes.map((note) => {
									return api.delete(`/organizers/${organizer.id}/notes/${note.id}`)
								})
								
								return Promise.all(notesPromises)
							}).then(() => {	
								return api.get(`/organizers/${organizer.id}/dashboard`);
							})
							.then((response) => {
								const dashboardObj = response?.data;
								
								return api.put(`organizers/${organizer.id}/dashboard/${dashboardObj.id}`, { dashboard: null });
							})
							.then(() => {
								setDialog({
									title: 'Reset Form Data',
									message: 'Form data has been reset',
									allowClose: true
								});
							})
							.catch((error) => {
								console.error(error);
								setDialog({
									title: 'Reset Form Data',
									message: 'Failed to reset form data',
									allowClose: true
								});
							});
					}
				});

				enableDialog();
			}
		},
		{
			label: 'Reload Prior Year',
			onClick: (organizer) => {
				setDialog({
					title: 'Reload Prior Year Data',
					message: 'Reloading prior year data for organizer...',
					allowClose: false
				});

				enableDialog();

				api.post(`/organizers/${organizer.id}/pull`, { returnType: 'X' }).then((response) => {
					setDialog({
						title: 'Reload Prior Year Data',
						message: 'Request to reload prior year data has been sent.',
						allowClose: true
					});
				}).catch((error) => {
					console.error(error);
					setDialog({
						title: 'Reload Prior Year Data',
						message: 'Failed to reload prior year data!',
						allowClose: true
					});
				})
			}
		},
		{
			label: 'Delete All Documents',
			onClick: (organizer) => {
				setDialog({
					title: 'Delete All Documents',
					message: 'Are you sure you want to delete all documents?',
					allowConfirm: true,
					allowClose: true,
					onConfirm: () => {
						setDialog({
							title: 'Delete All Documents',
							message: 'Deleting all documents...',
							allowConfirm: false,
							allowClose: false
						});

						api.get(`organizers/${organizer.id}/documents`).then((response) => {
							return response.data.results;
						}).then((documents) => {
							const documentPromises = documents.map((document) => {
								return api.delete(`/organizers/${organizer.id}/documents/${document.id}`);
							});

							return Promise.all(documentPromises);
						}).then(() => {
							setDialog({
								title: 'Delete All Documents',
								message: 'All documents have been deleted',
								allowClose: true
							});
						}).catch((error) => {
							console.error(error);
							setDialog({
								title: 'Delete All Documents',
								message: 'Failed to delete all documents',
								allowClose: true
							});
						});
					}
				});

				enableDialog();
			}
		},
		{
			label: 'Edit Year End date',
			onClick: (organizer) => {

				api.get(`/organizers/${organizer.id}`).then((response) => {

					const basicData = response.data.forms.find((form) => form.key === 'basicData')

					if (basicData !== undefined) {

						const currentTaxYearDate = parseToISODate(basicData.data[0].groups[9].fields[1].value)

						setYearEndDate(currentTaxYearDate)
						setDialog({
							title: 'Edit tax year end',
							message: '',
							allowConfirm: true,
							allowClose: true,
							onConfirm: () => {

								const newTaxYearValue = document.getElementById("taxYear").value

								basicData.data[0].groups[9].fields[1].value = parseToUSDate(newTaxYearValue)

								const data = { key: 'basicData', data: basicData.data, status: 2 };

								api.put(`/forms/${organizer.id ?? ''}`, data).then(() => {
									setDialog({
										title: 'Tax year end set',
										message: 'The year end date was successfully changed',
										allowConfirm: false,
										allowClose: true
									});
									enableDialog();

								}).catch((error) => {
									setDialog({
										title: 'Tax year failed to set',
										message: 'The year end date failed to be changed',
										allowConfirm: false,
										allowClose: true
									});
									enableDialog();
									console.log(error);
								})
							}
						});
						enableDialog();
						return basicData;

					} else {
						setDialog({
							title: 'Edit tax year end',
							message: 'Please open the basic data form before editing',
							allowConfirm: false,
							allowClose: true,
						})
						enableDialog();
					}
				})
			}
		},
	];

	const handleCreateOrganizer = (params) => {
		// build update object from parameters with values
		let updateObj = {};

		Object.keys(params).forEach((key) => {
			if (params[key]) {
				updateObj[key] = params[key];
			}
		});

		api.post(`/organizers`, updateObj).then((response) => {
			const groupObj = { groups: updateObj.groups };
			if (updateObj.groups) {
				api.put(`/organizers/${response.data.id}/groups`, groupObj).catch((error) => {
					console.error(error);
				});
			}

			disableCreateDialog();

			setDialog({
				title: 'Create Organizer',
				message: 'Organizer created',
				allowClose: true
			});

			enableDialog();
			toggleTableReload();
		}).catch((error) => {
			console.error(error);
			disableCreateDialog();

			setDialog({
				title: 'Create Organizer',
				message: 'Failed to create organizer',
				allowClose: true
			});

			enableDialog();
		});
	};

	const openEditDialog = (resource) => {
		if (!resource.id) {
			console.error('Resource to edit does not have an ID');
			return;
		}

		setTargetOrganizer(resource);
		enableEditDialog();
	};

	const handleEditOrganizerCancel = () => {
		setTargetOrganizer(null);
		disableEditDialog();
	};

	const handleEditOrganizerConfirm = (changes) => {
		const { lockedState, hasChangedLocked, assignedGroups, hasChangedGroups } = changes;
		const targetOrganizerId = targetOrganizer.id;

		const promises = [];

		if (hasChangedLocked) {
			promises.push(api.put(`/organizers/${targetOrganizerId}`, { locked: lockedState, lastUserActivityOn: new Date() }));
		}

		if (hasChangedGroups) {
			promises.push(api.put(`/organizers/${targetOrganizerId}/groups`, { groups: assignedGroups }));
		}

		handleEditOrganizerCancel();
		setDialog({
			title: 'Edit Organizer',
			message: 'Updating organizer...',
			allowClose: false
		})
		enableDialog();

		Promise.all(promises).then(() => {
			setDialog({
				title: 'Edit Organizer',
				message: 'The organizer was sucessfully updated',
				allowClose: true
			})
			enableDialog();
			toggleTableReload();
		}).catch((err) => {
			console.error(`An error has occurred while editing organizer ${targetOrganizer.id}`);
			console.error(err);
			setDialog({
				title: 'Edit Organizer',
				message: 'An error has occurred while updating this organizer',
				allowClose: true
			})
			enableDialog();
		}).finally(() => {
			setTargetOrganizer(null);
		});
	};

	const filterFn = (searchValue) => (item) => {
		return item.client?.name?.toLowerCase().includes(searchValue);
	};

	return (
		<div className="pracDashboardSize">
			<Breadcrumb items={navItems} />
			<h1>Organizers</h1>
			<CreateOrganizerDialog visible={showCreateDialog} onCreate={handleCreateOrganizer} onClose={disableCreateDialog} />
			<EditOrganizerDialog visible={showEditDialog} onConfirm={handleEditOrganizerConfirm} onCancel={handleEditOrganizerCancel} targetOrganizer={targetOrganizer} />
			<Dialog
				id="organizer-action-dialog"
				role="alertdialog"
				visible={showDialog}
				onRequestClose={() => { }}
				aria-labelledby="organizer-action-dialog-title"
				data-testid="organizer-action-dialog-title"
			>
				<DialogHeader>
					<DialogTitle>{dialog.title}</DialogTitle>
				</DialogHeader>
				<DialogContent>
					{dialog.title === 'Edit tax year end' && dialog.message !== 'Please open the basic data form before editing' ?
						<input type="date" id="taxYear" value={yearEndDate} onChange={(event) => { setYearEndDate(event.target.value) }} /> :
						dialog.message
					}
				</DialogContent>
				<DialogFooter>
					<Button id="organizer-action-dialog-confirm" theme="warning" disabled={!dialog.allowConfirm} onClick={dialog.onConfirm}>Confirm</Button>
					<Button id="organizer-action-dialog-close" onClick={disableDialog} disabled={!dialog.allowClose}>
						Close
					</Button>
				</DialogFooter>
			</Dialog>
			<RestfulTable
				resourceName={resourceName}
				resourceUri={resourceUri}
				columns={columns}
				defaultSortKey={defaultSortKey}
				defaultSortOrder={defaultSortOrder}
				createOverRide={enableCreateDialog}
				editOverRide={openEditDialog}
				allowRowClick={true}
				actions={actions}
				reloadTable={requestTableReload}
				showPriorYearData={true}
				filterFn={filterFn}
			/>
		</div>
	);
}

export default ManageOrganizers;
