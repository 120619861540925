import React, { useState, useEffect } from 'react';
import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRendererHelper from '@components/formRenderer';
import { pullFieldValue } from '@utilities/pullFieldValue';
import { triggeredEvent } from './politicalCampaignAndLobbyingLogicTrigger';
import * as NAV from '@utilities/constants/navigation';
import NoActiveClient from '@views/dashboard/noActiveClientPage';
import useLoadOrganizer from '@utilities/hooks/useLoadOrganizer';


const PoliticalCampaignActivitiesLineSection = [
	{
		title: 'Section 527 Political Organization Service/Address Details',
		isVisible: true,
		sectionId: 1,
		isCollapsed: false,
		isDenseRow: true,
		groups: [
			{
				groupId: 1,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Description of Services', name: 'polLob527SrvcDescLabel', type: 'label', col: 12 },
					{ label: 'Please provide details', name: 'polLob527SrvcDesc', type: 'textarea', maxLength: 900, class: 'fieldTextArea', col: 12 },
				]
			},
			{
				groupId: 2,
				isVisible: true,
				fields: [
					{ label: 'Street Address', name: 'polLob527Add1', type: 'freeText', col: 9, printFieldWidth: 500, },
					{ label: 'Country', name: 'polLob527Foreign', type: 'select', lookup: 'FOREIGN_ASSETS_DISPOSITION_COUNTRIES', col: 3, printFieldWidth: 300, logicFunction: [{ trigger: 'showPolCamProvince', isDifferentGroup: 'true' }] },
					{ label: 'City', name: 'polLob527City', type: 'freeText', col: 4, printFieldWidth: 300, },
					{ label: 'Province', name: 'polLob527StateProv', type: 'freeText', col: 5, printFieldWidth: 300, isVisible: true },
					{ label: 'State', name: 'polLob527State', type: 'select', lookup: 'STATES', col: 5, printFieldWidth: 300, isVisible: false },
					{ label: 'Province', name: 'polLob527Province', type: 'select', lookup: 'BASIC_DATA_PROVINCES', col: 5, printFieldWidth: 300, isVisible: false },
					{ label: 'Zip', name: 'polLob527Zip', type: 'zippostal', col: 3, printFieldWidth: 250, },
				],
			},

		]
	},
];

export const sections = [
	{
		title: 'Political Activities',
		isVisible: true,
		sectionId: 1,
		groups: [
			{
				groupId: 1,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Did the organization engage in direct or indirect political campaign activities on behalf of or in opposition to candidates for public office?', name: 'reqSchdChkCiLabel', type: 'label', col: 8 },
					{ label: '', name: 'reqSchdChkCi', type: 'radio', lookup: 'YESNO_RADIO', col: 4, logicFunction: [{ trigger: 'showPoliticalCampaignActivities', isDifferentGroup: 'true' }] },
				]
			},
			{
				groupId: 2,
				isVisible: false,
				hasDivider: true,
				fields: [
					{ label: 'Did the organization engage in lobbying activities, or have a section 501(h) election in effect during the tax year?', name: 'reqSchdChkCiiLabel', type: 'label', col: 8 },
					{ label: '', name: 'reqSchdChkCii', type: 'radio', lookup: 'YESNO_RADIO', col: 4, logicFunction: [{ trigger: 'showPoliticalCampaignActivities', isDifferentGroup: 'true' }] },
				]
			},
			{
				groupId: 3,
				isVisible: false,
				hasDivider: true,
				fields: [
					{ 
						label: 'Does the organization receive membership dues, assessments, or similar amounts?', 
						name: 'reqSchdChkCiiiLabel', 
						type: 'label', 
						col: 8 
					},
					{ 
						label: '', 
						name: 'reqSchdChkCiii', 
						type: 'radio', 
						lookup: 'YESNO_RADIO', 
						col: 4, 
						logicFunction: [{ trigger: 'showPoliticalCampaignActivities', isDifferentGroup: 'true' }] 
					},
				]
			},
		]
	},
	{
		title: 'Political Campaign Activities',
		isVisible: false,
		hasDenseRow: true,
		sectionId: 2,
		groups: [
			{
				groupId: 1,
				isVisible: false,
				hasDivider: true,
				fields: [
					{ label: `Provide a description of the organization's direct and indirect political campaign activities`, name: 'polLobActLabel', type: 'label', maxLength: 900, col: 12 },
					{ label: `Please provide details`, name: 'polLobAct', type: 'textarea', maxLength: 900, class: 'fieldTextArea', col: 12 },
				]
			},
			{
				groupId: 2,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Total expenditures in conducting political activities', name: 'polLobActExpLabel', type: 'label', col: 10 },
					{ label: 'Amount', name: 'polLobActExp', type: 'money', col: 2 },

				]
			},
			{
				groupId: 3,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Total volunteer hours in conducting political activities', name: 'polLobActVolHrsLabel', type: 'label', col: 10 },
					{ label: 'Hours', name: 'polLobActVolHrs', type: 'total', col: 2 },

				]
			},
			{
				groupId: 4,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'What was the total excise tax incurred by the organization?', name: 'polLobC3ExcsTxOrgLabel', type: 'label', col: 10 },
					{ label: 'Amount', name: 'polLobC3ExcsTxOrg', type: 'money', col: 2 },
				]
			},
			{
				groupId: 5,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: `What was the total excise tax incurred by the organization's managers?`, name: 'polLobC3ExcsTxOrgMgrLabel', type: 'label', col: 10 },
					{ label: `Amount`, name: 'polLobC3ExcsTxOrgMgr', type: 'money', col: 2 },
				]
			},
			{
				groupId: 6,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Did the organization file Form 4720 to report excise tax related to political activity in the tax year?', name: 'polLobC3File4720Label', type: 'label', col: 8 },
					{ label: '', name: 'polLobC3File4720', type: 'radio', lookup: 'YESNO_RADIO', col: 4, logicFunction: [{ trigger: 'showForm4720', isDifferentGroup: 'true' }] },
				]
			},
			{
				groupId: 7,
				isVisible: false,
				hasDivider: true,
				fields: [
					{ label: 'Would the organization like assistance filing?', name: 'polLobC3File4720AsstLabel', type: 'label', col: 8 },
					{ label: '', name: 'polLobC3File4720Asst', type: 'radio', lookup: 'YESNO_RADIO', col: 4 },
				]
			},
			{
				groupId: 8,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Was a correction made?', name: 'polLobC3CorrectLabel', type: 'label', col: 8 },
					{ label: '', name: 'polLobC3Correct', type: 'radio', lookup: 'YESNO_RADIO', col: 4, logicFunction: [{ trigger: 'showCorrectionMade', isDifferentGroup: 'true' }] },
				]
			},
			{
				groupId: 9,
				isVisible: false,
				hasDivider: true,
				fields: [
					{ label: 'Describe the correction made', name: 'polLobC3CorrectDescLabel', type: 'label', col: 12 },
					{ label: 'Please provide details', name: 'polLobC3CorrectDesc', type: 'textarea', maxLength: 900, class: 'fieldTextArea', col: 12 },

				]
			},
			{
				groupId: 10,
				isVisible: false,
				hasDivider: true,
				fields: [
					{ label: 'Explain reason for not correcting', name: 'polLobC3NoCorrectRsnLabel', type: 'label', col: 12 },
					{ label: 'Please provide details', name: 'polLobC3NoCorrectRsn', type: 'textarea', maxLength: 900, class: 'fieldTextArea', col: 12 },
				]
			},
			{
				groupId: 11,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Provide the total amount directly expended by the organization for section 527 exempt function activities', name: 'polLob527TotAmtLabel', type: 'label', col: 10 },
					{ label: 'Amount', name: 'polLob527TotAmt', type: 'money', col: 2 },
				]
			},
			{
				groupId: 12,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: `Provide the total amount of the organization's internal funds contributed to other organizations for section 527 exempt function activities.`, name: 'polLob527IntrnlAmtLabel', type: 'label', col: 10 },
					{ label: 'Amount', name: 'polLob527IntrnlAmt', type: 'money', col: 2 },
				]
			},
			{
				groupId: 13,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Did the organization file Form 1120-POL for this year?', name: 'polLob527Form1120Label', type: 'label', col: 8 },
					{ label: '', name: 'polLob527Form1120', type: 'radio', lookup: 'YESNO_RADIO', col: 4, logicFunction: [{ trigger: 'show527InternalAmout', isDifferentGroup: 'true' }] },
				]
			},
			{
				groupId: 14,
				isVisible: false,
				hasDivider: true,
				fields: [
					{ label: 'Would the organization like assistance filing?', name: 'polLob527Form1120AsstLabel', type: 'label', col: 8 },
					{ label: '', name: 'polLob527Form1120Asst', type: 'radio', lookup: 'YESNO_RADIO', col: 4 },
				]
			},
			{
				groupId: 15,
				isVisible: true,
				fields: [
					{ label: 'Provide the following information about each Section 527 political organization to which the organization made payments:', name: 'polLob527Label', type: 'label', col: 10 },
				]
			},
			{
				groupType: 'lineItem',
				groupId: 16,
				isVisible: true,
				fields: [
					{ label: 'Name', name: 'polLob527Name', type: 'freeText' },
					{ label: 'EIN', name: 'polLob527EIN', type: 'ein' },
					{ label: `Amount`, name: 'polLob527AmtPd', type: 'money', isTotal: true },
					{ label: 'Amount', name: 'polLob527AmtRcvd', type: 'money', isTotal: true },
					{ type: 'lineButtons' },
				],
				lineItems: [],
				entities: [],
				currentEntity: -1,
				lineSections: PoliticalCampaignActivitiesLineSection,
                headerLabelsPrintStyles: {gridTemplateColumns: '2fr 2fr 1.75fr 1.75fr 1fr'},
                footerLabelsPrintStyles: {gridTemplateColumns: '6fr 2fr 1fr'},
				lineItemDetails:
				{
					headerLabels: [
						{ label: 'Name', name: 'polLob527Name', col: 3, colTablet: 2, styles: {textAlign: 'left', paddingLeft: '48px'} },
						{ label: 'EIN', name: 'polLob527EIN', col: 2, colTablet: 1, styles: {textAlign: 'left', paddingLeft: '42px'} },
						{ label: `Amount Paid from the Organization's Internal Funds`, name: 'polLob527AmtPd', col: 3, colTablet: 2, styles: {textAlign: 'left', paddingLeft: '35px'} },
						{ label: 'Amount Received by the Organization and Paid Directly to Section 527 Organization', name: 'polLob527AmtRcvd', col: 3, colTablet: 2, styles: {textAlign: 'left', paddingLeft: '27px'} },
						{ label: 'Actions', col: 1, colTablet: 1, align: 'left' }
					],
					footerLabels: [
						{ type: 'buttons', col: 12, buttonTitle: 'Add Another Organization', icon: 'add' },
						{ type: 'totalMoney', name: 'polLob527AmtPd', col: 8, colTablet: 5, label: '$ 0' },
						{ type: 'totalMoney', name: 'polLob527AmtRcvd', col: 3, colTablet: 3, label: '$ 0' }

					],
					lineButtons: [
						{ button: 'remove', },
					]
				}
			},
		]
	},
	{
		title: 'Lobbying',
		isVisible: false,
		sectionId: 3,
		groups: [
			{
				groupId: 1,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: 'Has your organization filed IRS Form 5768 and elected to calculate lobbying expenditure limits under Section 501(h)?',
						name: 'polActDtl5768Label',
						type: 'label',
						col: 8
					},
					{
						label: '',
						name: 'polActDtl5768',
						type: 'radio',
						lookup: 'YESNO_RADIO',
						col: 4,
						logicFunction: [{ trigger: 'showOrganizationsExpenditure', isDifferentGroup: 'true' }],
						prior: true,
						isPriorEditable: true,
						priorCompareOptions: {
							radioShouldLookup: true
						},
						axcess: {
							pull: {
								fields: ['XFDS99C3.5'],
								fn: 'checkboxToRadioPull'
							}
						}
					},
				]
			},
			{
				groupId: 2,
				isVisible: false,
				hasDivider: true,
				groupType: 'lineItem',
				uploadType: 'drawer',
				class: 'householdEmploymentUpload',
				fields: [
					{ label: 'Please upload copy of Form 5768 (if not previously provided).', name: 'polActDtl5768AttachLabel', type: 'label', col: 9 },
					{ type: 'lineButtons', col: 3 }  
				],
				lineItems: [],
				lineItemDetails: {
					headerLabels: [],
					footerLabels: [],
					lineButtons: [
						{ button: 'uploadOnly', uploadSectionName: 'polActDtl5768Attach', viewSectionId: 0 },
					]
				}
			},
			{
				groupId: 3,
				isVisible: false,
				hasDivider: true,
				fields: [
					{
						label: 'Does the organization belong to an affiliated group?',
						name: 'polActDtlAffilGrpLabel',
						type: 'label',
						col: 8
					},
					{
						label: '', name: 'polActDtlAffilGrp',
						type: 'radio',
						lookup: 'YESNO_RADIO',
						col: 4,
						prior: true,
						isPriorEditable: true,
						priorCompareOptions: {
							radioShouldLookup: true
						},
						axcess: {
							pull: {
								fields: ['XFDS99C3.0'],
								fn: 'checkboxToRadioPull'
							}
						}
					},
				]
			},
			{
				groupId: 4,
				isVisible: false,
				hasDivider: true,
				fields: [
					{
						label: 'Do limited control provisions apply?',
						name: 'polActDtlCntrlProvApplyLabel',
						type: 'label',
						col: 8
					},
					{
						label: '',
						name: 'polActDtlCntrlProvApply',
						type: 'radio',
						lookup: 'YESNO_RADIO',
						col: 4,
						prior: true,
						isPriorEditable: true,
						priorCompareOptions: {
							radioShouldLookup: true
						},
						axcess: {
							pull: {
								fields: ['XFDS99C3.1'],
								fn: 'checkboxToRadioPull'
							}
						}
					},
				]
			},
			{
				groupId: 5,
				isVisible: false,
				fields: [
					{ label: 'Provide the following:', name: 'N/A', type: 'label', col: 12 }, // TODO: Recreate as a grid
				]
			},
			{
				groupId: 6,
				isVisible: false,
				hasDivider: true,
				fields: [
					{ label: 'Total lobbying expenditures to influence public opinion (grass roots lobbying)', name: 'grassRootsLobbying', type: 'label', col: 8 },
					{ label: 'Filing Organization Total', name: 'polActDtlCatOrgGrssRts', type: 'money', col: 2 },
					{ label: 'Affiliated Group Total', name: 'polActDtlCatGrpGrssRts', type: 'money', col: 2 },
				]
			},
			{
				groupId: 7,
				isVisible: false,
				hasDivider: true,
				fields: [
					{ label: 'Total lobbying expenditures to influence a legislative body (direct lobbying)', name: 'directLobbyingLabel', type: 'label', col: 8 },
					{ label: 'Filing Organization Total', name: 'polActDtlCatOrgDrctLbby', type: 'money', col: 2 },
					{ label: 'Affiliated Group Total', name: 'polActDtlCatGrpDrctLbby', type: 'money', col: 2 },
				]
			},
			{
				groupId: 8,
				isVisible: false,
				hasDivider: true,
				fields: [
					{ label: 'Other exempt purpose expenditures', name: 'otherExemptLabel', type: 'label', col: 8 },
					{ label: 'Filing Organization Total', name: 'polActDtlCatOrgOther', type: 'money', col: 2 },
					{ label: 'Affiliated Group Total', name: 'polActDtlCatGrpOther', type: 'money', col: 2 },
				]
			},
			{
				groupId: 9,
				isVisible: false,
				fields: [
					{ label: 'During the year, did the organization attempt to influence foreign, national, state or local legislation, including any attempt to influence public opinion on a legislative matter or referendum through any of the following methods  (Complete all that apply):', name: 'N/A', type: 'label', col: 12 },
				]
			},
			{
				groupType: 'lineItem',
				isVisible: false,
				groupId: 10,
				hasDivider: true,
				isShowFooter: false,
				fields: [
					{ label: 'Other', name: 'polActDtlNo5768Type', type: 'freeText' },
					{ label: 'Yes', name: 'polActDtlNo5768Apply', type: 'checkbox', isFieldCheckbox: true, logicFunction: [{ trigger: 'showPolActDltNo5768DescAndExpAmt', isDifferentGroup: true }] },
					{ label: 'Type', name: 'polActDtlNo5768Desc', isVisible: false, type: 'freeText' },
					{ label: 'Amount', name: 'polActDtlNo5768ExpAmt', isVisible: false, type: 'money', isTotal: true },
				],
				prePopulate: [
					{ label: 'Volunteers', prePopulateAugments: [{ name: 'volunteersApply' }, { name: 'volunteersDesc' }, { name: 'volunteersExpAmt' }] },
					{ label: 'Paid staff or management', prePopulateAugments: [{ name: 'paidStaffOrManagementApply' }, { name: 'paidStaffOrManagementDesc' }, { name: 'paidStaffOrManagementExpAmt' }] },
					{ label: 'Media advertisements', prePopulateAugments: [{ name: 'mediaAdvertisementsApply' }, { name: 'mediaAdvertisementsDesc' }, { name: 'mediaAdvertisementsExpAmt' }] },
					{ label: 'Mailings to members, legislators or the public', prePopulateAugments: [{ name: 'mailingsMembersApply' }, { name: 'mailingsMembersDesc' }, { name: 'mailingsMembersExpAmt' }] },
					{ label: 'Publications or published or broadcast statements', prePopulateAugments: [{ name: 'publicationsApply' }, { name: 'publicationsDesc' }, { name: 'publicationsExpAmt' }] },
					{ label: 'Grants to other organizations for lobbying purposes', prePopulateAugments: [{ name: 'grantsToOtherOrgApply' }, { name: 'grantsToOtherOrgDesc' }, { name: 'grantsToOtherOrgExpAmt' }] },
					{ label: 'Direct contact with legislators, their staffs, government officials or a legislative body', prePopulateAugments: [{ name: 'directContactApply' }, { name: 'directContactDesc' }, { name: 'directContactExpAmt' }] },
					{ label: 'Rallies, demonstrations, seminars, conventions, speeches, lectures or any other means', prePopulateAugments: [{ name: 'ralliesDemonstrationsApply' }, { name: 'ralliesDemonstrationsDesc' }, { name: 'ralliesDemonstrationsExpAmt' }] },
					{ label: 'Other activities', prePopulateAugments: [{ name: 'otherActivitiesApply' }, { name: 'otherActivitiesDesc' }, { name: 'otherActivitiesExpAmt' }] }
				],
				prePopulateControls: ['label', 'checkbox', 'freeText', 'money'],
				prePopulateIndexes: [{ currentIndex: 1 }, { currentIndex: 2 }, { currentIndex: 3 }],
				lineItems: [],
				entities: [],
				headerLabelsPrintStyles: {gridTemplateColumns: '1.75fr .75fr 1.75fr 1.75fr'},
				lineItemDetails:
				{
					headerLabels: [
						{ label: 'Activity Type', col: 4, colTablet: 2 },
						{ label: 'Activity Apply', col: 2, colTablet: 1 },
						{ label: 'Describe Activity', col: 4, colTablet: 2 },
						{ label: 'Amount of Expenditures', col: 2, colTablet: 1 },
					],
					footerLabels: [
						{ type: 'totalMoney', name: 'noncashDtlItmAmt', col: 12, colTablet: 6, label: ' $ 0' },
					],
					lineButtons: []
				}
			},// TODO: Recreate as a grid
			{
				groupId: 11,
				isVisible: false,
				hasDivider: true,
				fields: [
					{ label: 'Did any of the activities above cause the organization to not be described in Section 501(c)(3)?', name: 'polActDtlNo5768XcsLabel', type: 'label', col: 8 },
					{ label: '', name: 'polActDtlNo5768Xcs', type: 'radio', lookup: 'YESNO_RADIO', col: 4, logicFunction: [{ trigger: 'totalExciseTax', isDifferentGroup: 'true' }] },
				]
			},
			{
				groupId: 12,
				isVisible: false,
				hasDivider: true,
				fields: [
					{ label: 'What was the total excise tax incurred by the organization?', name: 'polActDtlNo5768XcsOrgAmtLabel', type: 'label', col: 10 },
					{ label: 'Amount', name: 'polActDtlNo5768XcsOrgAmt', type: 'money', col: 2 },

				]
			},
			{
				groupId: 13,
				isVisible: false,
				hasDivider: true,
				fields: [
					{ label: `What was the total excise tax incurred by the organization's managers?`, name: 'polActDtlNo5768XcsMgmtAmtLabel', type: 'label', col: 10 },
					{ label: 'Amount', name: 'polActDtlNo5768XcsMgmtAmt', type: 'money', col: 2 },
				]
			},
			{
				groupId: 14,
				isVisible: false,
				hasDivider: true,
				fields: [
					{ label: 'Did the organization file Form 4720 for the tax year?', name: 'polActDtlNo5768Form4720Label', type: 'label', col: 8 },
					{ label: '', name: 'polActDtlNo5768Form4720', type: 'radio', lookup: 'YESNO_RADIO', col: 4, logicFunction: [{ trigger: 'orgFileForm4720', isDifferentGroup: 'true' }] },
				]
			},
			{
				groupId: 15,
				isVisible: false,
				hasDivider: true,
				fields: [
					{ label: 'Would the organization like assistance filing?', name: 'polActDtlNo5768Form4720AsstLabel', type: 'label', col: 8 },
					{ label: '', name: 'polActDtlNo5768Form4720Asst', type: 'radio', lookup: 'YESNO_RADIO', col: 4 },
				]
			},
			{
				groupId: 16,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Were substantially all (90% or more) of the dues received nondeductible by members?', name: 'polActPrxySub90DuesLabel', type: 'label', col: 8 },
					{ label: '', name: 'polActPrxySub90Dues', type: 'radio', lookup: 'YESNO_RADIO', col: 4, logicFunction: [{ trigger: 'politicalExpenditureExpenses', isDifferentGroup: 'true' }] },
					{
						name: 'listOne', type: 'label', lookup: '', col: 12,
						htmlLabel:
							<React.Fragment key={'lobbying'}>
								<ul key={'lobbying-listOne'} className='discList'>
									<div>Note:  Answer yes if your organization meets any of the criteria below.</div>
									<li key={'lobbying-list-1'} className='discListItem'> Any organization that keeps records to substantiate that 90% or more of its members can’t deduct their dues (or similar amounts) as business expenses whether or not any part of their dues are used for lobbying purposes.</li>
									<li key={'lobbying-list-2'} className='discListItem'>  An organization that receives more than 90% of dues from the following types of organizations:
										<div> A 501(c)(3) organizations</div>
										<div> A state or local government </div>
										<div> A local association of employees' and veterans' organizations described in section 501(c)(4), but not section 501(c)(4) social welfare organizations </div>
										<div> A labor union or other labor organization described in section 501(c)(5), but not section 501(c)(5) agricultural and horticultural organizations</div></li>
									<li key={'lobbying-list-3'} className='discListItem'> A local association of employees' and veterans' organizations described in section 501(c)(4), but not section 501(c)(4) social welfare organizations</li>
									<li key={'lobbying-list-4'} className='discListItem'> A labor union or other labor organization described in section 501(c)(5), but not section 501(c)(5) agricultural and horticultural organizations</li>
									<li key={'lobbying-list-5'} className='discListItem'> A public utility or the exercise of any essential governmental function</li>
									<li key={'lobbying-list-6'} className='discListItem'> A section 501(c)(4) or section 501(c)(5) organization that receive more than 90% of their annual dues from Persons, Families, or Entities, who each paid annual dues of $169 or less in 2019</li>
									<li key={'lobbying-list-7'} className='discListItem'> An organization that receives a private letter ruling from the IRS stating that the organization satisfies the section 6033(e)(3) exception.</li>
									<li key={'lobbying-list-8'} className='discListItem'> An organization that isn’t a membership organization</li>
								</ul>
							</React.Fragment>,
					},

				],


			},
			{
				groupId: 17,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Did the organization make only in-house lobbying expenditures of $2,000 or less?', name: 'polActPrxyInHouseLabel', type: 'label', col: 8 },
					{ label: '', name: 'polActPrxyInHouse', type: 'radio', lookup: 'YESNO_RADIO', col: 4, logicFunction: [{ trigger: 'politicalExpenditureExpenses', isDifferentGroup: 'true' }] },
				]
			},
			{
				groupId: 18,
				isVisible: true,
				hasDivider: false,
				fields: [
					{ label: 'Did the organization agree to carryover lobbying and political expenditures from the prior year?', name: 'polActPrxyCarryOvrLabel', type: 'label', col: 8 },
					{ label: '', name: 'polActPrxyCarryOvr', type: 'radio', lookup: 'YESNO_RADIO', col: 4, logicFunction: [{ trigger: 'politicalExpenditureExpenses', isDifferentGroup: 'true' }] },
				]
			},
			{
				groupId: 19,
				isVisible: false,
				fields: [
					{ label: 'Provide the following amounts:', name: 'textThree', type: 'label', col: 12 },
				]
			},
			{
				groupId: 20,
				isVisible: false,
				hasDivider: false,
				fields: [
					{ label: 'Dues, assessments, and similar amounts from members:', name: 'polActPrxyDueslabel', type: 'label', col: 10 },
					{ label: 'Amount', name: 'polActPrxyDues', type: 'money', col: 2 },
				]
			},
			{
				groupId: 21,
				isVisible: false,
				hasDivider: false,
				fields: [
					{ label: 'Non-deductible lobbying and political expenditures from the current year:', name: 'polActPrxyNonDedCYLabel', type: 'label', col: 10 },
					{ label: 'Amount', name: 'polActPrxyNonDedCY', type: 'money', col: 2 },
				]
			},
			{
				groupId: 22,
				isVisible: false,
				hasDivider: false,
				fields: [
					{
						label: 'Non-deductible lobbying and political expenditures carried over from the prior year:',
						name: 'polActPrxyNonDedPYLabel',
						type: 'label',
						col: 10,
					},
					{
						label: 'Amount',
						name: 'polActPrxyNonDedPY',
						type: 'money',
						col: 2,
						...pullFieldValue(['XFDS99C6.7'], true)
					}
				]
			},
			{
				groupId: 23,
				isVisible: false,
				hasDivider: true,
				fields: [
					{ label: 'Aggregate amount of dues reported to members as non-deductible:', name: 'polActPrxyAggAmtDuesLabel', type: 'label', col: 10 },
					{ label: 'Amount', name: 'polActPrxyAggAmtDues', type: 'money', col: 2 },

				]
			},
		]
	},
	{  
    title: 'Additional Information',
    collapseClass: 'collapsibleForm',
    isVisible: true,
    isDefaultExpanded: false,
		isOptionalSectionMerge: true,
    sectionId: 4,
    groups: [
      {
        groupId: 2,
        groupType: 'details',
        fields: [
          { 
            label: 'Please use the area below to provide any additional information you think we may need or questions you would like to discuss.', 
            type: 'label', 
            class: 'labelLeftLight' 
          },
        ]
      },
      {
        groupId: 1,
        fields: [
          {
            label: 'Start typing to provide additional information', 
            name: 'additionalInfoTextArea', 
            type: 'textarea', 
            col: 12,
						maxLength: 9000
          }
        ]
      }
    ]
  },
];

export const getPolCampaigLobAnoTitles = () => {
	let titles = [];
	sections.forEach((section) => {
		if (!titles.includes(section.title)) {
			titles.push(section.title);
		}
	});
	return titles;
};

function PolCampaigLobAno(props) {
	const { isExportForm } = props;
	const { updateCard, REDUX } = usePageFramework(NAV.POLITICAL_CAMPAIGN_AND_LOBBYING);
	const [sectionState, setSectionState] = useState('');
	const {isActiveClient, isLoading} = useLoadOrganizer();

	useEffect(() => {
		if (!isExportForm) updateCard();
		setSectionState(sections);
		// eslint-disable-next-line	
	}, []);

	const axcessIdentifiers = [
		{ section: 'Schedules', id: 'Sch C' },
	];

	return (
		<>
			{!isLoading && isActiveClient && <FormRendererHelper
				sections={sectionState}
				key={REDUX.POLITICAL_CAMPAIGN_AND_LOBBYING}
				formName={REDUX.POLITICAL_CAMPAIGN_AND_LOBBYING}
				identifiers={axcessIdentifiers}
				triggeredEvent={triggeredEvent}
				isExportForm={isExportForm} />}
			{!isLoading && !isActiveClient && <NoActiveClient />}
		</>
	);
}

export default PolCampaigLobAno;