import { SCREEN_BREAKPOINTS } from "../constants/screenSizes";
import useWindowDimensions from "./useWindowDimensions";

const useAdaptive = () => {
    const { width } = useWindowDimensions();

    return {
        isMobile: width < SCREEN_BREAKPOINTS.TABLET,
        isTablet: width >= SCREEN_BREAKPOINTS.TABLET && width < SCREEN_BREAKPOINTS.LAPTOP,
        isLaptop: width >= SCREEN_BREAKPOINTS.LAPTOP && width < SCREEN_BREAKPOINTS.DESKTOP,
        isLaptopOrDesktop: width >= SCREEN_BREAKPOINTS.LAPTOP,
        isDesktop: width >= SCREEN_BREAKPOINTS.DESKTOP,
        isDesktopDevice: navigator.userAgentData?.mobile === false,
    }
};

export default useAdaptive;